body {
  &.educators {

    .hs-form.stacked label {
      display: none !important;
    }

    .ss-form .submitted-message {
      color: $white;
    }

    .hs-form.stacked fieldset {
      max-width: 100%;
    }

    .hs-form.stacked .field {
      margin-bottom: 0 !important;
    }

    .hs-form input {
      box-sizing: border-box !important;
    }

    .hs-form input[type=email] {
      border-radius: 4px !important;
      font-size: 16px !important;
      line-height: 19px !important;
      padding: 12px 16px !important;
      font-family: "Open Sans" !important;
      margin: 8px 0 8px 0 !important;
      height: 48px !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      outline: -webkit-focus-ring-color auto 0 !important;
    }

    .main-content {
      h1 {
        font-family: Raleway;
        font-size: 54px;
        line-height: 56px;
        padding-top: 24px;
        padding-bottom: 0px;
      }
      h2 {
        font-family: Raleway;
        font-size: 36px;
        line-height: 48px;
        padding-top: 24px;
        padding-bottom: 0px;
      }
      h3 {
        font-family: Raleway;
        font-size: 24px;
        line-height: 28px;
        padding-top: 24px;
        padding-bottom: 0px;
      }
      h4 {
        font-family: Raleway;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding-top: 24px;
        padding-bottom: 0px;
      }
      h5 {
        font-family: Raleway;
        font-size: 16px;
        line-height: 24px;
        padding-top: 24px;
        padding-bottom: 0px;
      }
    }

    .footer{
      height: auto;
    }
    .pls-input {
      width: 100%;
    }
    padding-bottom: 50px !important;
  }
  &.educators-submitted {
    padding-bottom: 74px !important;
  }
  &.educators {
    .main-content {
      span.section1-main-header {
        font-family: Raleway;
        font-size: 34px;
        line-height: 40px;
        color: #272b33;
      }
      p.section1-secondary-header {
        font-family: Raleway;
        font-size: 24px;
        line-height: 1.3;
        color: #616b80;
      }
      span {
        &.section2-main-header, &.section3-main-header, &.section4-main-header, &.section5-main-header {
          font-family: Raleway;
          font-size: 24px;
          line-height: 1.3;
          color: #272b33;
        }
      }
      p {
        font-family: "Open Sans";
        font-size: 18px;
        line-height: 1.5;
        color: #616b80;
        margin-top: 25px;
      }
      padding-bottom: 65px;
      .b2b-form {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        text-align: center;
        opacity: 1;
        background-color: #272b33;
        -webkit-transition: height .3s ease;
        transition: height .3s ease;
        z-index: 1;
        &.active {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          opacity: 1;
          background-color: #272b33;
          -webkit-transition: height .3s ease;
          transition: height .3s ease;
        }
      }
      .b2b-form-text {
        font-family: Raleway;
        font-size: 16px;
        color: #f5f5f5;
      }
      img {
        max-width: 100%;
      }
    }
    .main-content {
      form {
        text-align: left !important;
      }
      form {
        ol .ss-form-question {
          &:nth-child(odd) {
            float: left;
            margin-left: 15px;
          }
          &:nth-child(even) {
            float: right;
            margin-left: 15px;
          }
        }
      }
      iframe textarea {
        padding-top: 10px;
      }
      .ss-item.ss-navigate {
        position: relative;
        top: 195px;
        left: -250px;
        input[type=submit] {
          background-color: #00A0DF !important;
          border: 1px solid #00A0DF;
          padding: 14px;
          color: white;
        }
      }
      .ss-required-asterisk {
        display: none;
      }
      form ol .ss-form-question {
        &:nth-child(1) input {
          margin-left: 19px;
        }
        &:nth-child(3) input {
          margin-left: 24px;
        }
      }
      .b2b-form-x {
        font-size: 18px;
        color: #f5f5f5;
        position: absolute;
        right: 30px;
        cursor: pointer;
      }
      form .has-error {
        border: 1px solid red;
      }
      .b2b-form-success {
        margin-top: 16px;
      }
    }
  }
}

@media (max-width: 767px) {
  body.educators {
    .main-content .b2b-form {
      &.active {
        height: 575px;
        overflow: scroll;
        height: auto;
        padding-bottom: 8px;
      }
      height: auto;
      padding-bottom: 8px;
    }
    padding-bottom: 83px !important;
  }
}

@media (max-width: 290px) {
  body.educators {
    padding-bottom: 110px !important;
  }
}

@media (max-width: 645px) {
  body.educators .main-content .ss-item.ss-navigate {
    position: relative;
    top: 165px;
    left: -265px;
  }
}

@media (max-width: 644px) {
  body.educators .main-content .ss-item.ss-navigate {
    top: 135px;
    left: -265px;
  }
}

@media (max-width: 633px) {
  body.educators .main-content .ss-item.ss-navigate {
    top: 0px;
    left: 14px;
  }
}

@media (max-width: 588px) {
  body.educators .main-content {
    .ss-item.ss-navigate {
      top: 0px;
      left: 14px;
    }
    form ol .ss-form-question {
      &:nth-child(odd), &:nth-child(even) {
        float: none !important;
        margin-left: 15px;
      }
      &:nth-child(2) input {
        margin-left: 20px;
      }
      &:nth-child(3) input {
        margin-left: 25px;
      }
      &:nth-child(4) input {
        margin-left: 33px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body.educators .main-content .ss-item.ss-navigate {
    top: 195px;
    left: -265px;
  }
}

@media (max-height: 625px) {
  .b2b-form.active {
    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
  }
}