/* Global Site Styles
*
* Please drop all common CSS in this file
*
*/
/* Bootstrap Overrides/Modifiers */
.no-gutter [class*="col-"] {
	padding-left:0 !important;
	padding-right:0 !important;
}

.rounded-box {
    border-radius: 4px;
    min-height: 275px;
}

.pls-input--orange {
    border-color: #FF9319 !important;
}

.audio-seek.miniplayer {
    width: calc(100% - 125px) !important;
}

.audio-seek.fake {
    width: calc(100% - 125px) !important;
}

.audio-seek.fake .audio-seek-bar {
    width: 2%;
}

h1.miniplayer {
    font-family: Raleway !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 1.5 !important;
    padding: 0 !important;
}

h2.miniplayer {
    font-family: Raleway !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 1.5 !important;
    padding: 0 !important;
}

.google-badge {
    max-width: 135px;
    margin: auto;
}

body.admin {
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    -o-user-select: text !important;
    user-select: text !important;
}

.learn-right-sidebar-wrapper * {
    -webkit-user-select: text !important;
}

input, textarea {
	-webkit-user-select: text !important;
	-khtml-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	-o-user-select: text !important;
	user-select: text !important;
}

button{
    border:none;
    background:none;
}

html {
	height:100%;
}

body.modal-open {
	margin-right:0;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
	margin:0 0 .2em;
}

.navbar-fixed-bottom {
    top: inherit !important;
}

.border-pls-blue-steel {
    border: 1px solid #616b80;
}

.border-pls-black {
    border: 1px solid #000000;
}

.learn-master-complete .modal-sm {
    max-width: 480px;
}

.pls-red-error {
    border: 1px solid #F21818 !important;
    color: #FF9E15 !important;
}

a.pls-orange:hover {
    color: #FF9E15;
}

.light{
    font-weight:300;
}

.referrals-card-number {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    color: #FFF;
    border: none;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    background-color: #ff9e15;
    margin: auto;
    padding-top: 8px;
}

.margin-auto {
    margin: auto;
}

.text-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
}

.overflow-hidden{
	overflow: hidden;
}

body.library .list-inline > li {
	padding:0;
}

.copy-button {
    font-size: 12pt;
}

.no-shadow {
    box-shadow: none !important;
}

.btn-flat-mugatu {
    background: #f21818 !important;
    color: #000;
    text-shadow:none !important;
}

.btn-flat-grey {
    background:#6D6E71 !important;
    color: #FFF;
    text-shadow:none !important;
}

.btn-flat-grey-light {
    background:#CECECE !important;
    color: #FFF;
    text-shadow:none !important;
}

.btn-flat-dark-blue {
    background:#09759C !important;
    color: #FFF;
    text-shadow:none !important;
}

.btn-flat-blue{
    background:#00B6FF !important;
    color: #FFF;
    text-shadow:none !important;
}

.btn-flat-blue-inverse{
    background:#FFF !important;
    color: #00A0DF !important;
    text-shadow:none !important;
    border: 1px solid #00A0DF;
}

.btn-flat-dark-blue{
	background:#09759c !important;
	color: #FFF;
	text-shadow:none !important;
}

.btn-flat-dark-blue-inverse{
	background:#FFF !important;
	color: #09759c !important;
	text-shadow:none !important;
	border: 1px solid #09759c;
}

.btn-flat-orange{
    background:#ff9e19 !important;
    color: #FFF;
    text-shadow:none !important;
}

.btn-flat-facebook {
    background: #4367ab !important;
    color: #FFF;
    text-shadow:none !important;
}

.btn-flat-red{
    background: #e9511c;
    text-shadow:none !important;
    color: #FFF;
}

.miniplayer-expand {
    font-size: 16px !important;
    font-family: Raleway;
    font-weight: lighter !important;
    border-radius: 4px !important;
    float: right;
    color: #000 !important;
    text-shadow: none !important;
    background: #FFFFFF !important;
}

.btn-fb-share {
    font-size: 14px;
    padding: 2px 10px 3px 9px;
    border-radius: 4px;
}

.btn-fb-share i {
    font-size: 20px;
}

.btn-fb-share:hover {
    background-color: #5b7bd5 !important;
}

.btn-twitter-share {
    font-size: 14px;
    padding: 2px 10px 3px 9px;
    border-radius: 4px;
}

.btn-twitter-share:hover {
    background-color: #0c7abf !important;
}

.btn-twitter-share i {
    font-size: 20px;
}

.btn-flat-red-inverse{
    background: #FFF;
    text-shadow: none !important;
    color: #e9511c;
    border: 1px solid #e9511c;
}

.btn-flat-red-inverse:hover{
	color: #e9511c;
}

.btn-error{
    background: #f21818;
    color: #FFF;
    text-shadow: none !important;
    border: 1px solid #f21818;
}

.btn-error-inverse {
    background: #FFF;
    color: #f21818 !important;
    text-shadow:none !important;
    border: 1px solid #f21818;
}

.btn-error-inverse:hover, .btn-error-inverse:visited, .btn-error-inverse:active, .btn-error-inverse:focus{
    color: #F21818;
}


.btn-flat-green{
    background: #67c25e;
    text-shadow:none !important;
    color: #FFF;
}

.btn-flat-steel{
    background: #616B80;
    text-shadow:none !important;
    color: #FFF;
}

.btn-flat-pink{
    background: #FF3096;
    text-shadow:none !important;
    color: #FFF;
}

.btn-flat-green-inverse{
    background: #FFF;
    text-shadow:none !important;
    color: #67c25e;
    border: 1px solid #67c25e;
}

.btn-flat-green-inverse:hover{
	color: #67c25e;
}

.btn-flat-white {
    border:1px solid #EBEBEB;
    border-color: #EBEBEB !important;
	color: #333;
    background-image: none !important;
    background: #FFF;
    text-shadow:none !important;
}

.btn-flat-white:hover {
	color: #333;
}

.btn-flat-dry-cement {
    border:1px solid #000000;
    border-color: #000000 !important;
    color: #333;
    background-image: none !important;
    background: #dbdbdb;
    text-shadow:none !important;
}

.btn-link {
    border: none;
    box-shadow: none;
    text-shadow: none;
}

.btn-flat-dry-cement:hover {
    color: #333;
}

.btn-flat-cloudy, .btn-flat-cloudy:hover{
    background: #F5F5F5 !important;
    color: #616B80;
    text-shadow:none !important;
}

.btn-flat-meekus, .btn-flat-meekus:hover, .btn-flat-meekus:visited, .btn-flat-meekus:focus{
    background: #18f291 !important;
    color:#FFF;
    text-shadow:none !important;
}

.btn-flat-matilda, .btn-flat-matilda:hover, .btn-flat-matilda:visited, .btn-flat-matilda:focus{
    background: #fff2f2 !important;
    color:#333 !important;
    text-shadow:none !important;
}


.btn-small {
    padding: 2px 10px;
    font-size: 11.9px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.btn-large-bold {
    font-size: 20px;
    font-weight: bold;
}

.froala-box {
    height: calc(100% - 75px);
    padding-bottom: 50px;
}

.froala-view {
    height: 100%;
}

.icon-align {
    vertical-align: -15% !important;
}

.btn.btn-flat-blue-inverse{
    background:#FFF !important;
    color: #00A0DF;
    text-shadow:none !important;
    border: 1px solid #00A0DF;
}

.main-content.left-sidebar {
	margin-left:260px;
    width: calc(100% - 260px);
}

.main-content .container.full {
	padding-left:0;
	padding-right:0;
	cursor: default;
}

/* Card rating colors */
.score-1 {
    border-color:#AE0808 !important;
    color: #AE0808 !important;
}

.score-2 {
    border-color:#B95C09 !important;
    color: #B95C09 !important;
}

.score-3 {
    border-color:#E2CF09 !important;
    color: #E2CF09 !important;
}

.score-4 {
    border-color:#65940A !important;
    color: #65940A !important;
}

.score-5 {
	border-color:#049D0B !important;
    color: #049D0B !important;
}


/* Fixes on jquery Validation Engine */
.formError .formErrorContent {
	line-height:normal;
    white-space: nowrap;
    background: #8F8F8F;
}

.formError:after {
    border-top-color: #8F8F8F;
}

.ui-autocomplete {
	z-index:1001!important;
}

.confidence-rating{
    border: 1px solid #777;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border-radius: 3px;
    font-size: 12px;
    color: #777;
}

#impersonate-box {
    position: fixed;
    width: auto;
    background-color: #33361D;
    color: #fff;
    border: 1px rgb(255, 184, 0) solid;
    left: 0;
    top: 0;
    padding: 0px 5px;
    margin:0;
    z-index: 1000;
    font-size: 9px;
}

#impersonate-box a {
	color:#88f;
}

.navbar-form i#search-loader {
	display:none;
	font-size:16px;
	position:absolute;
	right:28px;
	top:5px;
}

.navbar-form .search-query {
    background: #333;
    color:#d2d2d2
}

.navbar-form .search-query.animated {
    width: 80px;
}

/* Dark skin for Intro mode - END */

.nav-tabs > li.active > a,.nav-tabs > li.active > a:hover,.nav-tabs > li.active > a:focus {
	background-color:transparent;
}

.primary-sidebar .nav > li > a {
	text-shadow:none;
}

.primary-sidebar .nav > li > a.accordion-toggle span {
	font-weight:600;
	text-shadow:none;
	font-size:14px;
	color:#5f5f5f;
}

.beta-badge {
    float:right;
    width: 175px;
}

.primary-sidebar .nav > li > a i + span {
	padding-left:0;
}

.navbar-static-top .navbar-collapse {
	padding-left:15px;
	padding-right:15px;
}

/* Fixes for small browser sizes */
.collapse.navbar-collapse.navbar-collapse-top.left-col {
	display: block;
	float: left;
}

.collapse.navbar-collapse.navbar-collapse-top.left-col .nav.navbar-nav {
	float: left;
}

.collapse.navbar-collapse.navbar-collapse-top.left-col .nav.navbar-nav ul.dropdown-menu {
	position: absolute;
}

.dropdown-menu .divider {
	display: block;
}

#tab-comments-quickpreview {
    padding-left: 5px;
}

div.gig-comments-container {
    width: 300px;
}

div.gig-comments-container .gig-comments-comment-child {
    margin-left: 10px!important;
}

.splitter {
	width: 2px;
	height: 100%;
	border-left: 2px solid #78808A;
	margin: 3px 4px 0 7px;
	padding: 33px 0 0;
}

body .navbar-left button.link {
	background: none;
	border: none;
	color: #78808A;
	width: 40px;
	height: 40px;
	position: relative;
}

body .navbar-left button.link i {
	font-size: 1.8em;
}

.login-menu-btn {margin-left: 10px;}

li.login-menu-btn a{
    color: #00a0df !important;
    border: 2px solid #00a0df;
		transition: all .35s;
    font-size: 16px;
    margin-top: 19px;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.login-menu-btn:hover a {color: white !important; background: #00a0df !important; border-radius: 4px;}

body .centered-block button.thick-button i.icon-plus-circle {
	position: absolute;
	top: 0px;
	left: 6px;
	font-size: 1.1em;
}

body .navbar-left button.link:hover i {
	color: #FFFFFF;
}

/* Fix for search field on small browsers */
@media all and (max-width: 990px) {
	.navbar-inverse .navbar-form {
		padding: 0 15px;
		box-shadow: none;
	}
}

.dropdown-menu li > a {
    clear: both;
    display: block;
    line-height: 1.42857;
    padding: 3px 20px;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
}

ul.ui-autocomplete li i {
	float: right;
	padding-top: 5px;
}

ul.ui-autocomplete li i:hover {
	font-size: 16px;
	cursor:pointer;
}

ul.ui-autocomplete li button {
    float: left;
    width: 85%;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
	border: medium none;
	padding: 4px;
	text-align: left;
}

ul.ui-autocomplete li button:hover {
	background-color: #eee;
}

ul.ui-autocomplete .inplaylist {
	color: #28C0E8;
}

ul.ui-autocomplete .inplaylist:hover {
	cursor: default;
	font-size: 14px;
}

.add-top-more{
    padding-top:30px;
}

.add-bottom-more{
    padding-bottom:30px !important;
}

.add-margin-top {
    margin-top: 15px;
}

.add-margin-top-twenty {
    margin-top: 20px;
}

.add-margin-bottom {
    margin-bottom: 15px;
}

.add-margin-bottom-ten {
    margin-bottom: 10px;
}

.add-margin-top-ten {
    margin-top: 10px;
}

.add-top{
    padding-top:15px;
}

.add-top-margin{
    margin-top:5px;
}

.add-top-less{
    padding-top:5px;
}

.add-small-top{
    padding-top:5px;
}

.add-bottom{
    padding-bottom:15px !important;
}
.add-bottom-less{
    padding-bottom:5px;
}

.add-right{
    margin-right:15px !important;
}

.add-right-more{
    margin-right:30px !important;
}

.add-left{
    margin-left:15px !important;
}

.add-left-more{
    margin-left:30px !important;
}

.little-left{
    margin-left:10px !important;
}

.really-little-right{
    margin-right:5px !important;
}

.dashboard h1 i {
    font-size: 0.9em !important;
}

.little-top{
    padding-top:5px;
}

.less-top{
    padding-top:10px;
}

.less-top-margin {
    margin-top:10px;
}

.little-margin {
    margin-top: 2px !important;
}

.less-bottom{
    padding-bottom:10px;
}
.no-top{
    padding-top:0px !important;
}

.no-bottom{
    padding-bottom:0px !important;
}

.no-bottom-margin{
    margin-bottom:0px !important;
}

.no-top-margin{
	margin-top:0px !important;
}

.no-margins{
	margin: 0px !important;
}

.negative-right {
    margin-right: -40px !important;
}

.fill-height {
    min-height: 100%;
}

.fill-window {
    min-height: calc(100vh - 80px);
}

body.nav-hidden .fill-window {
    min-height: calc(100vh - 40px);
}

body.nav-hidden.auth .fill-window {
    min-height: calc(100vh - 18px);
}

.disabled {
	-moz-opacity: 0.50 !important;
	opacity: 0.50 !important;
	cursor: auto !important;
}

body .jcrop-vline, body .jcrop-hline {
	background: #ffffff url("../img/Jcrop.gif");
}

.mejs-controls .mejs-button button:focus {
	outline: inherit;
}

.mejs-container .mejs-controls .mejs-time span {
	margin-right: 7px;
}

.no-border {
    border: none !important;
}

.changelog a {
    color: #00A0DF !important;
}

.box {
    background: none;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow:hidden;
}

.thick-border {
    border-width: 3px !important;
}

@viewport {
    orientation: landscape;
}

#set_modal .modal-footer .modal_load {
    padding:5px 10px;
    display:none;
}

.tagsContainer{
    margin:10px 0;
    clear:both;
}
.messageTag{
    background:#00A0DF;
    border-radius:3px;
    color:#FFF;
    float:left;
    margin-right:7px;
    padding:5px 7px;

}
.messageTag:hover{
    box-shadow:0 0 4px #333;
}
.messageTag i{
    cursor:pointer;
}

.pointer {
    cursor:pointer;
}

.no-pointer {
    cursor:auto !important;
    pointer-events: none;
}

.cursor-default {
    cursor: default !important;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

a.forgot-password, a.goto-register, a.goto-login, a.terms {
    color: #00A0DF;
}

span#note_saved_loading {
    margin-left: 8px;
    margin-top: 4px;
}

input[type=text]::-ms-clear {
	display: none;
}

.facebook-circle{
    background: #4367ab;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    line-height: 55px;
    text-align: center;
    font-size: 21pt;
    color: #FFF;
    margin: 10px auto;
}
.twitter-circle{
    background:#6baefc;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    line-height: 55px;
    text-align: center;
    font-size: 21pt;
    color: #FFF;
    margin: 10px auto;
}
.blue-circle{
    background: #00B6FF;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    min-width: 50px;
    line-height: 55px;
    text-align: center;
    font-size: 21pt;
    color: #FFF;
    margin: 10px auto;
    display:block;
    cursor: pointer;
}

.md-circle{
    border-radius: 25px;
    height: 50px;
    width: 50px;
    line-height: 49px;
    text-align: center;
    font-size: 21pt;
    color: #FFF;
    margin: 10px auto;
    display:block;
    cursor: pointer;
}

.normal-cursor {
    cursor: auto !important;
}

.divider{
    margin: 10px auto;
    border-bottom:1px solid #EEE;
}
.divider-light{
    margin: 10px auto;
    border-bottom:1px solid #FEFEFE;
}

.divider--wet-cement{
    margin: 10px auto;
    border-bottom:1px solid #AAACB3;
}

.divider--dry-cement{
    margin: 10px auto;
    border-bottom:1px solid #dcdcdc;
}

.vert-divider{
    height: 100%;
    border-right:1px solid #EEE;
}

.register-text {
    margin-top: -5px;
    padding-bottom: 10px;
}

.register-red {
    color: #d9534f;
}

.register-green {
    color: #6ec02a;
}

.checkbox input[type=checkbox]:hover {
    cursor: pointer;
}

body.register .checkbox input[type=checkbox] {
    font-size: 1.5em;
}


#picmonic_of_the_week_survey .modal-dialog {
    position: absolute;
    left: 50%;
    margin-left: -300px;
    top: 50%;
    margin-top: -300px;
}

.line-height-28 {
    line-height: 28px;
}

.referral-div {
    margin-left:-20px;
    padding-right: 40px;
    padding-left: 40px;
    margin-right:-20px;
    padding-top:20px;
    margin-bottom: -15px;
    border-top: 1px #e8e8e8 solid;
}

.qotd-button {
    border-radius:12px;
    font-size: 30px;
}


.small-text {
    font-size: 11px !important;
}

.larger-text {
    font-size: 18px;
}

.font-18 {
    font-size: 18px;
}

.line-height-1 { line-height: 1; }

.rating_block ul.dropdown-menu li {
    text-align: left;
    padding-left: 10px;
    border-bottom: 1px solid #9E9E9E;
    line-height: 35px;
}

.responsive-button {
    white-space: normal;
}

.calendar-icon {
    max-width: 50px;
}

.img-modal {
    margin:auto;
    max-height:230px;
}

@media (max-height: 500px) {
    .img-modal {
        display:none !important;
    }
}

@media (max-height: 500px) {
    .text-modal {
        display:none !important;
    }
}

.landing-youtube-wrap {
    width:100%;
    max-width:820px;
    margin:auto;
}

.landing-video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.landing-video-container iframe, .landing-video-container object, .landing-video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.rating_block {
    cursor: pointer;
}

.greyed-out * {
    color: #d3d3d3;
    cursor: default;
}

.rating_block ul.dropdown-menu li:hover {
    cursor: pointer;
}
.no-bottom-border { border-bottom: none !important; }
.dropdown.open .dropdown-menu#confidence-rating {
    padding-top: 0;
    padding-bottom: 0;
    top: 20px;
}
u {text-decoration: underline;}
strike {text-decoration: line-through;}

div[ui-view="template"] {
	min-height: 100vh;
}

.roadtrip h1 {
    margin-bottom: 28px;
    color: #0571ab;
    font-size: 2.125em;
    line-height: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}
.roadtrip p {
    text-align: center;
}

.bigger-icons i {
  font-size: 18px !important;
    cursor: pointer;
}

.allcaps {
    text-transform: uppercase;
}

.roadtrip label {
    font-size: 16px;
    font-family: "Helvetica Neue";
}

.roadtrip button {
    font-size: 24px;
}

.marketplace .stack-label span {
    display: none;
}

.icon-loading-large {
   font-size: 100px;
}

comments {
	display: block;
}
li comments li {
	margin-left: 20px;
}
comments ui, comments li{
	list-style-type: none;
}

.comment-wrapper .profile-pic-wrapper{
	width:40px;
	margin-right: 15px;
}

.comment-wrapper .comment-body-wrapper{
	width: calc(100% - 55px);
}
.comment-wrapper .comment-body-wrapper div{
        word-wrap: break-word;
}

.comments-white-space {
	white-space: pre-line;
}

.round-frame{
	border-radius: 50%;
}

tag .tag{
	background: #777;
	color: #FFF;
	padding:3px 8px;
	text-align:center;
	display: inline-block;
	position:relative;
	margin-left: 10px;
	margin-right: 5px;
	border-top-right-radius:2px;
	border-bottom-right-radius:2px;
	margin-bottom: 10px;
    line-height: 14px;
}
tag .tag:before{
	content: "";
	float: left;
	position: absolute;
	top: 0;
	left: -10px;
	width: 0;
	height: 0;
	border-color: transparent #777 transparent transparent;
	border-style: solid;
	border-width: 10px 10px 10px 0;
}
tag .tag:after{
	content:"";
	position:absolute;
	top:8px;
	left:0;
	float:left;
	width:4px;
	height:4px;
	-moz-border-radius:2px;
	-webkit-border-radius:2px;
	border-radius:2px;
	background:#fff;
}
tag .tag:hover{
	background: #00A0DF;
}

tag .tag:hover:before{
	border-color: transparent #00A0DF transparent transparent;
}

tag.tag-pls-red .tag{
    background-color:#e9511c;
}

tag.tag-pls-red .tag:before{
    border-color: transparent #e9511c transparent transparent;
}

.picmonic-thumbnail{
	position: relative;
	overflow: hidden;
	cursor: pointer;
}

.picmonic-thumbnail img{
	width: 100%;
	position: relative;
	z-index: 0;
}

.picmonic-thumbnail .card-icon{
	position: absolute;
	z-index: 1;
	top: 28.3%;
	left: 33.3%;
	text-align: center;
	width: 33.3%;
	background: #FFF;
	opacity: 0.8;
	color: #333;
	border-radius: 50%;
	height: 43.4%;
    display: none;
}

.picmonic-thumbnail .card-icon-pause{
    position: absolute;
    z-index: 2;
    top: 28.3%;
    left: 33.3%;
    text-align: center;
    width: 33.3%;
    background: #FFF;
    opacity: 0;
    color: #333;
    border-radius: 50%;
    height: 43.4%;
}

.related-picmonics-modal .picmonic-thumbnail .card-icon {
    opacity: 0.5;
}

.picmonic-thumbnail:hover .card-icon{
	opacity: 1;
    display: block !important;
}

.picmonic-thumbnail:hover .card-icon-pause{
    opacity: 0.5;
}

.picmonic-thumbnail .card-icon .icon-play {
	margin: 20% 0 0 10%;
}

.picmonic-thumbnail .card-icon-pause .icon-pause {
    margin: 20% 3% 0 10%;
}

.border-bottom-none {
    border-bottom: none !important;
}

.font4em {
    font-size: 4em;
}

.font2-5em {
    font-size: 2.5em;
}

.opensans {
    font-family: "Open Sans" !important;
}

.block-center {
    margin:auto;
}

/* Resource Tile styles */
.resources-tile-wrapper {
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid-column;
    margin-right: 15px;
    margin-left: 15px;
	padding-bottom: 20px;
}
.resource-tile-border{
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	box-shadow: 0 2px 1px rgba(0,0,0,0.1);
}

.resources-tile-wrapper img {
    margin: 0 auto;
}

.pill-button-wrapper{
	height:30px;
	border-radius: 15px;
	border: 1px solid #D5D5D5;
	overflow: hidden;
}

.pill-button-wrapper .pill-button{
	cursor:pointer;
	height:30px;
	line-height: 30px;
	color: #00A0DF;
}

.pill-button-wrapper .pill-button.selected{
	background: #00A0DF;
	color: #FFF;
}
.pill-button-wrapper .pill-button.first{
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.pill-button-wrapper .pill-button.last{
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.searchInput{
	position: relative;
}
.searchInput .icon-circle-o-notch{
	position: absolute;
	z-index:2;
	right: 8px;
	top: 8px;
}

.pls-border-red {
    border: solid 2px #e9511c !important;
}

.pls-border-green {
    border: solid 2px #67c25e !important;
}

.form-control{
	font-size: 13px !important;
	padding: 5px !important;
}
.form-control:focus {
    box-shadow: none !important;
}

.img-round {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

input[type="file"]  {
    color: #00A0DF;
}

.moderation-action-col{
    width: 250px;
}

.break-all{
    word-break:break-all;
}

body.question-of-the-day {
    padding-bottom: 0px !important;
}

#donkeybridge-page #SnapABug_Button, #donkeybridge-page .help, #donkeybridge-page .nav-subscribe-btn {
    display: none;
}

@media (max-width: 768px) {
    .marlee {
        max-width: 70%;
    }
}

.nav-subscribe-btn {
    color: #272B33 !important;
    border-radius: 4px;
    background-color: #FF9319;
    height: 32px !important;
    font-weight: normal !important;
    padding: 10px 16px;
    margin-top: 4px;
    font-size: 12px !important;
}

.store-section .nav-subscribe-btn {
    display: none;
}

body.login, body.register, body.learn, body.library {
	padding-bottom: 0px;
}

textarea.no-resize {
	resize: none;
}

.beta-fish{
    height: 14px;
    margin-right: 8px;
    margin-top: -2px;
}

.alpha-fish {
    height: 18px;
    padding: 0 5px 0 5px !important;
    margin-top: -3px;
}

.dropdown-menu{
    left: 15px;
}

.falsebounty-icon {
    font-size:11px;
    position:relative;
    top:-7px;
    left:3px;
}

.community-icon {
    font-size:13px;
}

.slider-wrapper .slider-handle{
    height: 8px;
    border-radius: 4px;
    background: #CCC;
    transition: background 0.3s;
}

.slider-filler {
    display: none;
}

.slider-wrapper:hover .slider-handle{
    background: #FFF;
}

.chrome-icon{
    background: url('../img/browser_icons--white.png') no-repeat -100px 0;
    height: 50px;
    width: 50px;
    margin: 10px auto;
    overflow: hidden;
}

.firefox-icon{
    background: url('../img/browser_icons--white.png') no-repeat 0 0;
    height: 50px;
    width: 50px;
    margin: 10px auto;
    overflow: hidden;
}

.picmonic-tile-404 {
    width: 100% !important;
}

.pad-left-28 {
    padding-left: 28px;
}