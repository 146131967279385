@keyframes pls-spin {
  0%  { transform:rotate(0deg); }
  100%{ transform:rotate(360deg); }
}

.pls-loading-icon {
  display: inline-block;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);

  img {
    animation: pls-spin 1.2s infinite linear;
  }
}

.pls-loading-icon, .pls-loading-icon img {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
}

.pls-loading-icon--x-small, .pls-loading-icon--x-small img {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  line-height: 14px;
}

.pls-loading-icon--extra-small, .pls-loading-icon--small img {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  line-height: 16px;
}

.pls-loading-icon--medium, .pls-loading-icon--medium img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  line-height: 64px;
}

.pls-loading-icon--large, .pls-loading-icon--large img {
  width: 128px;
  height: 128px;
  border-radius: 128px;
  line-height: 128px;
}