@charset "UTF-8";

/* Overrides */
$font-size: 16px;

@import "common/base";
@import "public/base";


body, html {
  -webkit-touch-callout: default !important;
  user-select: auto !important;
}

body{
  &.scroll-lock {
    height: 100%;
    overflow: hidden;
  }

  cursor: default;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  padding-top: $navbar_public_height;

  div[ui-view="template"] {
    min-height: calc(100vh - #{$navbar_public_height});
  }

  .main-content{
    margin: 0;
    width: 100%;
    min-height: calc(100vh - #{$navbar_public_height});
  }

  #compiledPageContent {
    overflow-x: hidden;
  }
}

.logo-404 {
  width: 128px;
}

.webinar-form {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.kendall {
  max-width: 250px;
}

.nclex-guarantee {
  width: 96px;
}

.picmosis-kids {
  max-width: 100%;
  height: auto;
  max-height: 200px;
}

.picmosis-kids-small {
  max-width: 100%;
  height: auto;
  max-height: 100px;
}

.memorang-and-picmonic {
  max-width: 100%;
  height: auto;
  max-height: 150px;
}