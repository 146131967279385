.advocate-bg {
  background-color: $deep-sea;
  background: url("../img/www/advocate/hero-advocate.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.whats-expected-bg {
  background-color: $deep-sea;
  background: url("../img/www/advocate/whats-expected-from-you.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.advocate-ul {
  list-style-image: url("../img/www/advocate/advocate-bullet.png");
  text-align: justify;
  line-height: 32px;

  li {
    line-height: 32px;
    font-size: 16px;
    font-family: "Open Sans";
  }
}