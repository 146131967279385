textarea, input[type="text"], input[type="password"], input[type="tel"], input[type="email"], input[type="number"], select {
  &.pls-input, &.hs-input {
    border-radius: 4px;
    background-color: $magnum;
    border: solid 1px $wet-cement;
    color: $white;
    font-size: 16px;
    line-height: 19px;
    padding: 12px 16px;
    font-family: "Open Sans";
    margin: 8px 0 8px 0;
    height: 48px;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: -webkit-focus-ring-color auto 0;
    width:100%;

    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: $wet-cement;
    }

    &:focus{
      border: solid 1px $blue;
      box-shadow: 0 0 4px 1px $jullians-eyes;
    }

    form.ng-submitted {
        &.ng-invalid, &.ng-invalid.ng-touched{
          border: solid 1px $error-red;
        }
        &.ng-invalid.pls-loading {
          border: none;
        }

      &:focus{
        box-shadow: 0 0 4px 1px $error-red;
      }
    }

    &--inline {
      width: auto;
    }

    &--sm {
      height: 36px;
      padding: 8px 16px;
    }

    &--light {
      background-color: $white;
      color: $black;

      &.no-focus:focus {
        box-shadow: none !important;
        border: solid 1px $wet-cement !important;
      }
    }

    &--textarea-lg {
      height: 275px;
    }

    &--textarea-md {
      height: 75px;
    }

    &--orange {
      background-color: $champagne;
      border-color: $orange;
      color: $black;
      
      &:focus{
        border: solid 1px $orange;
        box-shadow: 0 0 4px 1px $orange;
      }
    }

    .pls-input-group &{
      display:table-cell;
      width:100%;
      margin: 8px 0 8px 0;

      &:first-child{
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &[disabled]{
    background-color: #EEE !important;
  }
}

input[type="checkbox"] {
  &.pls-input {
    height: 14px;
    width: 14px;
    margin: 8px 0;
    cursor: pointer;
  }
}

input[type="radio"] {
  &.pls-input {
    height: 20px;
    width: 20px;
    vertical-align: middle;
    margin-top: 0;
  }
}

.pls-label {
  font-size: 14px;
  margin: -4px 0 8px 0;
  font-weight: 200;

  &--clickable {
    cursor: pointer;
  }
}

.pls-radio-label {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    color: $white;

  &--light {
    color: $black;
  }
}

.pls-input-group {
  display:table;
  width:100%;

  .pls-input-group-addon{
    display:table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 4px;
    border: solid 1px $wet-cement;
    font-size: 16px;
    line-height: 1;
    width: 48px;
    white-space: nowrap;

    &--orange {
      background-color: $peach;
      border-color: $orange;
      color: $black;
    }

    &:last-child{
      border-left: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

select {
  &.pls-input {
    background: $magnum url("../img/select-arrow-dark.png") no-repeat 96% 50%;

    &--light {
      background-color: $white;
      color: $black;
    }

    &--grey {
      background-color: $grey;
      border: none;
      color: $black;
    }
  }
}

select.form-control[disabled] {
  background-image: none;
}

.pls-attached-input {
  border: 1px solid $blue-steel;
  border-radius: 4px;
  display: inline-block;
  .pls-btn {
    border-radius: 4px 0 0 4px;
    height: 48px;
    line-height: 34px !important;
    border: none;
    border-right: 1px solid $blue-steel;
    box-shadow: none;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 50px;
    padding-right: 50px;
    cursor: pointer;
  }

  input {
    margin: 0 0 0 -3px;
    height: 48px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    padding-left: 16px;
    font-size: 16px;
    color: $black;
    width: 284px;
    &:focus {
      border: none;
    }
  }

  &--dark input {
    background-color: $magnum;
    border: solid 1px $wet-cement;
    color: $white;
  }
}

.input-message {
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 16px;
    margin-top: -4px;
    color: $white;
    text-align: right;

  &--success {
    color: $success-green;
  }

  &--error {
    color: $error-red;
  }
}

.pls-input-wrapper {
  i {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

.referral-copy-group .pls-attached-input {
  width: auto;
  input {
    width: 410px;
  }
}

.pls-drop-box {
  border: 4px dashed $grey;
  padding: 32px;
  text-align: center;

  &--invalid {
    border-color: $error-red;
  }

  &--drag-over {
    border-color: $magnum;
  }
}
