h1, h2, h3, h4, h5, h6 {
  margin: 0 0 .2em;
}

body {
  color: $magnum;
  font-family: $opensans;
  font-size: $font-size;
  line-height: 1.33em;
}

.clickable {
  cursor: pointer;
}
.unclickable {
  cursor: default;
}
.inline-block {
  display: inline-block;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.space-letters {
  letter-spacing: 4px;
}

.valign-text-top {
  vertical-align: text-top;
}

.pls-display-4 {
  font-family: $raleway;
  font-size: 7em;
  font-weight: lighter;
  line-height: 1.25em;
}

.pls-display-3 {
  font-family: $raleway;
  font-size: 3.5em;
  font-weight: normal;
  line-height: 1.5em;
}

.pls-display-2 {
  font-family: $raleway;
  font-size: 2.5em;
  font-weight: normal;
  line-height: 1.25em;
}

.pls-display-1 {
  font-family: $raleway;
  font-size: 2.125em;
  font-weight: normal;
  line-height: 1.5em;
}

.pls-headline-2{
  font-family: $opensans;
  font-size: 2em;
  font-weight: normal;
  line-height: 2em;
}

.pls-headline{
  font-family: $opensans;
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.5em;
}

.pls-p {
  font-size: 1.1428571429em;
  line-height: 1.1428571429em;
  font-weight: normal;
}

.pls-title {
  font-family: $opensans;
  font-size: 1.2em;
  font-weight: normal;
  line-height: 1.5em;
}

.pls-subheading {
  font-family: $opensans;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.5em;
}

.pls-description-text {
  color: $wet-cement;
  font-family: $opensans;
  font-size: 0.9em;
  line-height: 1.15em;
}

.pls-description-text--blue-steel {
  @extend .pls-description-text;
  color: $blue-steel;
}

.pls-capitalize {
  text-transform: capitalize;
}

.pls-uppercase {
  text-transform: uppercase;
}

.pls-lowercase {
  text-transform: lowercase;
}

.pls-line-through {
  text-decoration: line-through;
}

.pls-antialiased {
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 768px) {
  .centered-xs {
    text-align: center;
  }

  .left-xs {
    text-align: left;
  }

  .no-float-xs {
    float: none !important;
  }

  .float-xs-left {
    float: left;
  }
}

@media (max-width: 991px) {
  .centered-sm {
    text-align: center;
  }

  .no-float-sm {
    float: none !important;
  }
}

.lighter {
  font-weight: lighter !important;
}

@media (max-width: 1199px) {
  .centered-md {
    text-align: center;
  }

  .no-float-md {
    float: none !important;
  }
}

/* Fonts */

.pls-font-raleway {
  font-family: $raleway !important;
}

.pls-font-cabin {
  font-family: 'Cabin', sans-serif;
}

.pls-font-opensans {
  font-family: $opensans;
}

.border-radius-4 {
  border-radius: 4px;
}

@for $i from 1 through 9 {
  $weight: $i * 100;
  .pls-font-weight-#{$weight} {
    font-weight: $weight;
  }
}

.pls-list {
  list-style: disc;
  padding-left: 40px;
  margin: 16px;

  li {
    padding: 4px;
  }
}

/* Font Sizes */

$font-sizes: (
        10: (
                font-size: 10,
                line-height: 16
        ),
        12: (
                font-size: 12,
                line-height: 24
        ),
        14: (
                font-size: 14,
                line-height: 24
        ),
        16: (
                font-size: 16,
                line-height: 32
        ),
        18: (
                font-size: 18,
                line-height: 32
        ),
        20: (
                font-size: 20,
                line-height: 24
        ),
        22: (
                font-size: 22,
                line-height: 26
        ),
        24: (
                font-size: 24,
                line-height: 32
        ),
        28: (
                font-size: 28,
                line-height: 36
        ),
        32: (
                font-size: 32,
                line-height: 36
        ),
        40: (
                font-size: 40,
                line-height: 48
        ),
        48: (
                font-size: 48,
                line-height: 56
        ),
        64: (
                font-size: 64,
                line-height: 72
        ),
) !default;

@each $class, $sizes in $font-sizes {
  $font-size:   map-get($sizes, font-size);
  $line-height:   map-get($sizes, line-height);

  .pls-font-#{$class} {
      font-size: #{$font-size}px !important;
      line-height: #{$line-height}px !important;
  }
}

@for $i from 2 through 8 {
  $height: $i * .5;
  .pls-line-height-#{$i} {
    line-height: $height !important;
  }
}
