.picmonic-eye-home {
  width: 56px;
  height: 56px;
}

.app-store-box {
  width: 294px;
  margin-left: auto;
  margin-right: auto;
}

.bullet-section {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}

.bullet {
  border-radius: 50%;
  background-color: $wet-cement;
  height: 8px;
  width: 8px;
  display: inline-block;
  cursor: pointer;
}

.block--pad {
  padding-top: 96px;
  padding-bottom: 96px;

  &-small {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &-bottom-only {
      padding-top: 0;
  }

  &-top-only {
    padding-bottom: 0;
  }
}

@media(min-width: 768px) {
  .footer-about {
    position: absolute;
    margin-left: 15%;
  }
}

.arrows-section {
  padding: 80px;
}

@media (max-width: 768px) {
  .arrows-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.arrows-bg {
  background: url("../img/www/home/group-10.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.arrows-lg {
  max-width: 500px;
  padding: 40px;
}

.arrows-img {
  padding: 30px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .arrows-img {
    max-width: 400px;
  }
}

.vertically-center {
  align-self: center;
}

.a-smarter-you-in-less-time-bg {
  background-color: $deep-sea;
  background: url("../img/www/home/a-smarter-you-in-less-time-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.group-20-bg {
  background: url("../img/www/home/group-20.png");
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 1250px) {
    background-position-y: -100px;
  }
}

.group-8-bg {
  background-color: $blue;
  background: url("../img/www/home/group-8-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  }
}

.what-is-picmonic {
  background-color: $blue-steel;
  background: url("../img/www/home/picmonic_classroom_720.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.fade-deap-sea {
  background-image: linear-gradient(to bottom, $deep-sea 1%, rgba(50, 58, 77, 0) 25%);
}

.fade-deep-sea-right {
  background-image: linear-gradient(to right, rgba(50, 58, 77, 0), $deep-sea);
}

.ul-bullets {
  list-style-type: disc;
  padding-left: 40px;
}

.ul-bullets-new {
  list-style-type: disc;
  padding-left: 15%;
}

.we-get-it-img {
  max-width: 212px;
  width: 100%;
}

.group-buy-banner-close {
  line-height: 32px;
}

.testimonials-bg {
  background: url("../img/www/home/group-6.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.student-bg {
    background: linear-gradient(93deg, rgba(255, 191, 25, 0.4), $orange), url("../img/bg-student.jpg") no-repeat;
}

.home-learn-tile {
  width: 288px;
  height: 352px;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  float: left;
  margin-left: 24px;
  margin-bottom: 24px;

  img {
    border-radius: 4px;
  }
}

.tile-section {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 8.33333%;
  width: 83.33333%;


  .tweet-box {
    float: left;
    width: 40%;
    margin-left: 6%;
  }
}

.tile-wrapper {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  margin: auto;
}

@media (max-width: 992px) {
  .tile-section {
    width: 1950px;
    margin-left: 15px;

    &-testimonials {
      width: 1350px;
    }

    .tweet-box {
      width: 288px;
      margin-left: 15px;
      float: left;
    }
  }
  .tile-wrapper {
    overflow-x: scroll;
    width: auto;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tile-wrapper {
    width: 970px;
  }
  .tile-section {
    width: 100%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .tile-wrapper {
    width: 1170px;
  }
}

.stats-cards {
  max-width: 960px;
  margin: auto;
}