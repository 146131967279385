@media (min-width: 768px) {
  .about-us-white-box-wrapper{
    box-shadow: 0 0 8px 0 $black;
    border-radius: 4px;
    background: $white;
  }
}

.pls-line-height-32 {
  line-height:32px;
}

@media (max-width: 767px) {
  .about-us-white-box-wrapper{
    background: $white;
  }

  .text-center-xs {
    text-align: center;
  }
}

.bg-pls-rons-face{
  background: #AFA7A6;
}