

.flex {
  &-container {
    display: flex;
    &--vertical {
      flex-direction: column;
    }
    &--center {
      justify-content: center;
      align-items: center;
    }
  }

  &-box {
    display: flex;
  }

  &-wrap-wrap {
    flex-wrap: wrap;
  }

  &-justify-content-space-betweeen {
    justify-content: space-between;
  }

  &-direction-row {
    flex-direction: row;
  }

  &-direction-column {
    flex-direction: column;
  }

  &-align-center {
    align-items: center;
  }

  &-grow {
    flex-grow: 1;
  }

  &-grow-android {
    flex: 1 1 0;
  }

  &-shrink {
    flex-shrink: 1;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-align-content-center {
    align-content: center;
  }

  &-align-items-center {
    align-items: center;
  }

  &-item {
    flex: 1;

    &--auto {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
    }
  }
}

@for $i from 1 to 12 {
  .flex-columns--$i {
    column-count: $i;
  }

  .flex-column--$i {
    width: percent($i, 12);
  }
}

@media(max-width: $bp-sm) {
  .flex-container {
    display: block;
  }

  .flex-container-xs {
    display: flex !important;
  }


  @for $i from 1 to 12 {
    .flex-columns-xs--$i {
      column-count: $i;
    }

    .flex-column-xs--$i {
      width: percent($i, 12);
    }
  }
}

@media(min-width: $bp-sm) and (max-width: $bp-md) {
  .flex-container-md {
    display: flex !important;
  }


  @for $i from 1 to 12 {
    .flex-columns-md--$i {
      column-count: $i;
    }

    .flex-column-md--$i {
      width: percent($i, 12);
    }
  }
}

@media(min-width: $bp-md) {
  .flex-container-lg {
    display: flex !important;
  }


  @for $i from 1 to 12 {
    .flex-columns-lg--$i {
      column-count: $i;
    }

    .flex-column-lg--$i {
      width: percent($i, 12);
    }
  }
}
