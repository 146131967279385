.heading {
  &__icon-container {
    position: absolute;
    left: -80px;
    top: 0;
    width: 72px;
    margin-left: 13px;
    display: flex;
    height: 100%;
    flex-direction: column;
    text-align: center;
  }
  &__icon-text {
    line-height: 64px;
    height: 64px;
    color: $white;
    text-transform: uppercase;
  }
  &__icon-line {
    flex: 1;
    margin: auto;
    width: 1px;
    background: $wet-cement;
    height: 100%;
  }
}
