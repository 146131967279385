@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/font/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(../../assets/font/MaterialIcons-Regular.woff2) format('woff2'),
  url(../../assets/font/MaterialIcons-Regular.woff) format('woff'),
  url(../../assets/font/MaterialIcons-Regular.ttf) format('truetype');
}

.circle-icon-container {
  border-radius: 50%;
  text-align: center;
  i { vertical-align: middle; }
  &--72 {
    width: 72px;
    height: 72px;
    line-height: 64px;
    i {
      font-size: 40px;
    }
  }
  &--blue-steel {
    background-color: $blue-steel;
    border: 1px solid $wet-cement;
    color: white;
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  /* Rules for sizing the icon. */
  &.md-14 { font-size: 14px; }
  &.md-16 { font-size: 16px; }
  &.md-18 { font-size: 18px; }
  &.md-24 { font-size: 24px; }
  &.md-36 { font-size: 36px; }
  &.md-40 { font-size: 40px; }
  &.md-48 { font-size: 48px; }
  &.md-60 { font-size: 60px; }
  &.flip-horz{transform: scaleX(-1);}
  &.flip-vert{transform: scaleY(-1);}
}

.wf-loading i.material-icons { visibility: hidden; }
.wf-active, .wf-inactive { i.material-icons { visibility: visible; } }


.beta-icon {
  height: 20px;
}