body.question-of-the-day .main-content {
    background: #616B80;
}

body.qotd h1, body.qotd h2 {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    text-align: center;
}

body.qotd h1 {
    font-size: 40px;
}

body.qotd li {
    list-style-type: disc;
}

body.qotd li p {
    color: #616b80;
}

body.qotd .bottomlist li {
    font-size: 36px;
    margin-left: 20px;
}

body.qotd .div-header {
    padding: 10px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
}

body.qotd .div-footer {
    padding-left: 18px;
    padding-right: 18px;
    border-bottom-left-radius:4px;
    border-bottom-right-radius:4px;
}

body.qotd form ul {
    padding: 20px;
    font-size: 16px;
}

body.qotd .testimonial {
    font-size: 16px;
    line-height: 32px;
}

body.qotd .bottomlist li p {
    font-size: 24px;
}

body.qotd .gif-container {
    border-radius:4px;
    padding: 10px;
}

body.qotd .bottomlist {
    line-height: 32px;
    padding-left: 20px;
    color:#80dbff;
}

body.qotd h2.subheader {
    font-size: 36px;
}

.graduate-wrapper{
    font-size: 28px;
    color:#FFF;
    position:relative;
}
.graduate{
    width: 100px;
    top: -20px;
    position: absolute;
    left: -120px;
}

.answer-wrapper{
    margin:5px 0;
    padding: 2px;
    padding-left: 8px;
    max-width: 700px;
}

.qotd-tile-header {
    line-height: 1 !important;
    font-weight: bold !important;
}

.qotd-tile-text {
    display:block;
    text-align: center;
    font-size:16px;
}

.answer-wrapper:hover, .answer-wrapper.selected{
    cursor:pointer;
}

.answer-wrapper-no-pointer {
    cursor: default !important;
}

.answer-wrapper.correct{
    background: none;
}

.answer-wrapper.correct-selected{
    background: #f5f5f5 !important;
    border-radius: 8px;
}

.answer-wrapper.incorrect{
    margin-left: 6px;
}

.answer-wrapper.incorrect-selected{
    background: #F5F5F5;
    border-radius:8px;
}

.answer-wrapper p {
    margin: 5px 0 5px !important;
}

.qotd-results-checkbox {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.loading-div {
    height: 350px;
}

.lineheight28 {
    line-height: 28px;
}

.infusion-option label {
    color: #FFF !important;
}

.bottom-margin {
    margin-bottom: 5px !important;
}

.input-group .btn-flat-orange{
    margin-top: -2px !important;
    margin-left: -3px !important;
    height: 36px;
}

.secondary-picmonic-link {
    text-decoration: underline;
    color: #00A0DF;
    font-weight: bold;
}

.font2em {font-size: 2em !important;}

.font16px {font-size: 16px !important;}

.maxwidth300 {max-width: 300px !important; margin: 0 auto;}
.maxwidth500 {max-width: 500px !important; margin: 0 auto;}
.minheight500 {min-height: 500px;}
.width200 {width: 200px;}

.question-text {
    color: #272B33 !important;
    font-family: "Open Sans", "Raleway", Lucida Sans Unicode, Verdana, Helvetica, sans-serif !important;
    font-weight: 400 !important;
}

.qotd-white-box-wrapper {
    background: #FFF;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

.qotd-header-add-top {
    padding-top: 24px;
    padding-bottom: 24px;
}

.qotd-subscribe-cta {
    font-weight: normal !important;
    font-size: 1.5em;
    border-radius: 8px;
    margin-top: 26px;
}

.qotd-explanation {
    margin-bottom:20px;
}

.qotd-submit-cta {
    font-size: 18px;
    font-weight: normal !important;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
}

.qotd-subscribe-input, .qotd-share-input {
    border: 1px solid #AAACB3;
    border-radius: 8px !important;
    color: #AAACB3;
    height: 39px !important;
    font-size: 18px;
}

textarea.qotd-share-input { margin-top: 12px; height: auto !important; }

.qotd-modal-btn {
    font-size: 15px !important;
}

.qotd-explanation-box {
    margin-top: 24px;
    border-radius: 8px;
    padding: 16px;
}

.qotd-add-left {
    margin-left: 6px;
}

@media (min-width: 768px) {
    .qotd-picmonic-intro-box {
        padding-left: 5px;
    }
}


.pricing-testimonials-wrapper p {
    color: white;
}

.pricing-testimonials-wrapper .row .col-sm-4.col-xs-12 {
    float:none;
    display:inline-block;
    vertical-align:middle;
    margin-right:-4px;
}

.pricing-testimonial-video {
    max-width: 100%;
    max-height: 225px;
}

.nursing-special-wrapper .nursing-special-cta {
    cursor: pointer;
    border-radius: 8px;
    color: white;
    text-shadow: none;
    font-size: 1.75em;
    font-weight: normal;
}

.nursing-characters-img, .nursing-info-icon, .nursing-info-icon-badge, .nursing-info-icon-wings {
    margin: 0 auto;
}

.nursing-info-icon {max-width: 96px;}

.nursing-info-icon-wings {max-width: 130px;}

.nursing-info-icon-badge {max-width: 140px;}

@media (max-width: 991px) {
    .nursing-info-icon {width: 100%;}
}

@media (max-width: 475px) {
    .nursing-info-icon-badge {width: 100%;}
}

@media (max-width:800px) {
    .pricing-testimonial-video {
        max-width: 100%;
        max-height: 200px;
        margin: 0 auto;
    }
}

.testimonial-video-add-top {
    padding-top: 50px;
}

.pricing-testimonial-video-link i {
    position: absolute;
    top: calc(50% - 55px);
    left: calc(50% - 25px);
    font-size: 5em;
    color: white;
    opacity: .75;

}

.pricing-testimonial-video-link:hover i {
    opacity: .85;
}

.qotd-choice {
    margin: 6px 0 6px !important;
}

.pls-h1 {
    color: #272B33;
    font-family: Raleway;
    font-weight: 400;
    font-size: 54px;
    line-height: 56px;
    padding-top: 24px;
    padding-bottom: 0px;
}

body.question-of-the-day .main-content h2, .pls-h2 {
    font-family: Raleway;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    padding-top: 24px;
    padding-bottom: 0px;
}

body.question-of-the-day .main-content h3, .pls-h3 {
    color: #616B80;
    font-family: Raleway;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    padding-top: 24px;
    padding-bottom: 0px;
}

body.question-of-the-day .main-content h4, .pls-h4 {
    color: #616B80;
    font-family: Raleway;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-top: 24px;
    padding-bottom: 0px;
}

body.question-of-the-day .main-content .pricing-box-wrapper h3 {
    font-size: 14px;
    line-height: 24px;
}

body.question-of-the-day .main-content .pricing-box-wrapper h4 {
    padding-top: 0px;
}

.pls-h5 {
    color: #AAACB3;
    font-family: Raleway;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-top: 24px;
    padding-bottom: 0px;
}

body.question-of-the-day .pricing-box-wrapper h5 {
    color: #5f5f5f;
}

body.question-of-the-day .main-content .qotd-suggest-feedback-header {
    margin-top: 21px;
}

body.question-of-the-day .main-content .qotd-suggest-feedback-header a {
    text-shadow: none;
}

body.question-of-the-day .main-content hr {
    border-top: 2px solid #F5F5F5;
    margin: -1px 0;
    margin-top: 16px;
}

body.question-of-the-day .main-content p {
    font-size: 16px;
}

body.question-of-the-day .main-content input[type="radio"], body.question-of-the-day .main-content input[type="checkbox"] {
    margin: 2px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

body.question-of-the-day .modal-body h2.pls-h2 {
    padding-top: 0px;
}

.qotd-submit-cta {
    white-space: normal;
}

@media (max-width: 767px) {
    body.question-of-the-day .main-content h5 {
        font-size: 12px;
        line-height: 1;
    }

    body.question-of-the-day .main-content h3 {
        font-size: 16px;
        line-height: 20px;
    }

    .qotd-large-cta {
        padding-left: 0px !important;
    }

    .qotd-modal-btn, .qotd-submit-cta-response {
        width: 100% !important;
        margin-bottom: 10px;
    }

    body.question-of-the-day .modal-footer .btn+.btn {
        margin-left: 0px;
    }
}

.qotd-research-img {
    height: 175px;
    margin: 0 auto;
}

@media (max-width: 400px) {
    .qotd-submit-cta {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .qotd-subscribe-cta {
        font-size: 1.35em;
    }
}

.w-360 .qotd-subscribe-cta {
    font-size: 1.30em !important;
}

.w-360 .maxwidth300 {
    max-width: 265px !important;
}

@media(max-width: 320px) {
    .qotd-submit-cta-response {
        width: 93% !important;
        margin-bottom: 10px;
        margin-left: 7%;
    }

    .qotd-subscribe-cta {
        font-size: 1.10em;
    }

    span.btn.btn-flat-grey-light.qotd-submit-cta.qotd-modal-btn {
        font-size: 13.5px !important;
    }

    .qotd-white-box-wrapper img.text-center.img-responsive.maxwidth300 {
        max-width: 230px !important;
        margin: 0 auto;
    }
}

/*********************************************************
** START FAQ
**/
body.question-of-the-day .panel-group .panel {
    margin-bottom: 0;
    overflow: hidden;
    border-bottom: 2px solid #ddd;
}

body.question-of-the-day .panel-group .panel-heading+.panel-collapse .panel-body {
    border: none;
}

body.question-of-the-day .panel-default>.panel-heading {
    background: none;
}

body.question-of-the-day .panel-group .panel-heading h4.panel-title {
    padding-top: 0px;
}

body.question-of-the-day .panel-group .panel {
    border-radius: 0px;
}

body.question-of-the-day .panel {
    margin-bottom: 20px;
    background-color: white;
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

body.question-of-the-day .panel-body div {
    font-size: 14px;
}

body.question-of-the-day .panel-body div a {
    text-decoration: underline;
    color:#00B6FF;
}

body.question-of-the-day .center-list-items {
    position: relative;
    right: -5%;
}

.pls-red-qotd-incorrect {
    color: #f21818 !important;
}

.pls-black-link {
    color: #000000;
    text-decoration: underline;
}

.pls-black-link:hover {
    color: #ffffff;
}

.pls-green-qotd-correct {
    color: #18F291 !important;
}

.qotd-embed-responsive-item {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 285px;
    border: 0;
}