body[modality="keyboard"] .pls-btn {
  text-decoration: underline !important;
}
a.pls-btn {
  text-decoration: none;
}
.pls-btn {
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  font-size: 16px !important;
  line-height: 19px !important;
  min-height: 36px;
  color: $magnum;
  background-color: $blue;
  font-family: $raleway;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: none;

  &:hover, &:active, &:visited {
    color: $magnum;
  }

  .material-icons {
    vertical-align: middle;
    line-height: 20px;
  }

  &--dark {
    background: $magnum;
    color: $blue !important;
    text-transform: uppercase;
  }

  &--blue{
    background: $blue;
    color: $white;

    &-inverted{
      background: $white;
      color: $blue;
      border: 1px solid $blue;

      &:hover{
        background: $blue;
        color: $white;
      }
    }
  }
  &--primary-cta, &--primary-cta:visited {
    font-size: 14px !important;
    font-weight: 600;
    color: $white;
    transition: all .3s;
  }
  &--flat-new {
    font-size: 14px !important;
    color: $blue;
    font-weight: 600;
    transition: all .3s;
    background: transparent;
  }

  &.pls-btn--primary-cta, &.pls-btn--flat-new {
    &:hover{
      background-color: $white;
      color: $blue;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    }

    &:active {
      background-color: $jullians-eyes;
      color: $battleship-grey;
      box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      transition: none;
      border-color: transparent;
    }
  }

  &.share-icon-wrapper {
    background: transparent !important;
    color: $blue !important;

    &:hover {
      background: $blue !important;
      color: $white !important;
    }
  }

  &.pls-btn--beta {
    background: $white;
    color: $mugatu;
    border: 1px solid $mugatu;
    float: left;
    padding: 7px 5px 4px 12px !important;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 90px;
  }

  &.pls-btn--beta-feedback {
    background: $mugatu;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.pls-btn--alpha {
    background: $military-blue;
    color: $jullians-eyes;
    border: 1px solid $jullians-eyes;
    float: left;
    padding: 7px 5px 4px 12px !important;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 90px;
  }

  &.pls-btn--alpha-feedback {
    background: $jullians-eyes;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.pls-btn--public-beta {
    background: $white;
    color: $blue;
    border: 1px solid $blue;
    float: left;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.pls-btn--public-beta-feedback {
    background: $blue;
    color: $white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
  }

  &.pls-btn--android {
    background-color: $android-green;
    color: $white;

    &:hover, &:active, &:visited {
      color: $white;
    }
  }

  &.pls-btn--arctic {
    background: $arctic;
    color: $blue;
  }

  &--small {
    padding: 4px;
  }

  &--size-3 {
    padding: 16px 64px 16px 64px;
    height: auto;
    font-size: 24px;
  }
  &.pls-btn--red {
    background: $beta-red;
    color: $white;
  }

  &--full-width {
    width: 100%;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .65;
    background-color: $blue-steel;
    color: $deep-sea;
    box-shadow: none;
  }

  .input-group-btn & {
    height: 48px;
    box-shadow: none;

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  &.pls-btn--block {
    display: block;
    width: 100%;
  }

  @media (max-width: 768px) {
    &.pls-btn--mobile-block {
      display: block;
      width: 100%;
    }
  }

  &.pls-btn--cloudy {
    background: $cloudy;
    color: $dark-grey;

    &:hover, &:active, &:visited {
      color: $charcoal-grey;
    }
  }

  &.pls-btn--dashed {
    border: 2px dashed $deep-sea;
    border-radius: 8px;
    color: $deep-sea;
    background-color: transparent;
    transition: background 0.3s, color 0.3s;
    font-size: 20px;

    &:hover {
      background: $wet-cement;
      color: $white;
      border-color: $wet-cement;
    }
  }

  &.pls-btn--facebook {
    background-color: $facebook-blue;
    color: $white;

    &:hover, &:active, &:visited {
      color: $white;
    }

    i {
      margin-top: 4px;
    }
  }

  &.pls-btn--facebook-lowercase {
    background-color: $facebook-blue;
    color: $white;
    text-transform: inherit;

    &:hover, &:active, &:visited {
      color: $white;
    }

    i {
      margin-bottom: -4px;
      font-size: 24px;
      padding-right: 16px;
      border-right: solid 1px rgba(255, 255, 255, 0.3);
    }
  }

  &.pls-btn--unpadded {
    padding: 0;
  }

  &.pls-btn--flat {
    border-radius: 4px;
    padding: 8px 16px 8px 16px;
    font-size: 16px;
    line-height: 19px;
    height: 36px;
    color: $magnum;
    font-family: $raleway;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;

    &:hover {
      background: $light-grey;
      border-color: $wet-cement;
    }
  }

  &.pls-btn--sm {
    height: auto !important;
    min-height: auto !important;
    line-height: 1 !important;
    font-size: 12px;
  }

  &.pls-btn--flat-white {
    background: $white;
    color: $black;
  }

  &.pls-btn--ios {
    background-color: $black;
    color: $white;

    &:hover, &:active, &:visited {
      color: $white;
    }
  }

  &.pls-btn--large {
    text-transform: uppercase;
    padding: 12px 64px 12px 64px;
  }

  &.pls-btn--meekus {
    background: $meekus;
    color: $black;
  }

  &.pls-btn--mugatu {
    background: $mugatu;
    color: $white;

    &--inverse{
      background: $white;
      border: 1px solid $mugatu;
      color: $mugatu;
    }
  }

  &.pls-btn--outline-orange {
    border: 1px solid $orange;
    color: $orange;
  }

  &.pls-btn--orange {
    background: $orange;
    color: $black;

    &:visited {
      color: black;
    }
  }

  &.pls-btn--sunrise {
    background-image: linear-gradient(93deg, #ffbf19, $orange);

    transition: all .35s;
    &:hover {
      background-image: linear-gradient(93deg, lighten(#ffbf19, 12%), lighten(#FF9319, 12%));
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }

  &.pls-btn--gradient-blue {
    background-image:  linear-gradient(102deg, #00b6ff, #0075eb);
    border-radius: 24px;
    padding: 16px 72px 16px 72px;
    color: white;
    text-transform: none;
    font-size: 20px !important;

    transition: all .35s;
    &:hover {
      background-image: linear-gradient(102deg, lighten(#00b6ff, 12%), lighten(#0075eb, 12%));
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }

  &.pls-btn--shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &.pls-btn--peach {
      background-color: $peach;
      border-color: $orange;
      color: $black;
  }

  &.pls-btn--wet-cement {
    background-color: $wet-cement;
    border-color: $wet-cement;
    color: $black;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
  }

  &.pls-btn--inverted {
    background: $blue !important;
    color: $white !important;
    &:hover {
      background: $white !important;
      color: $blue !important;
    }
  }

  &.pls-btn--platinum {
    background: $platinum;
    color: black;
    &:hover {
      background: $wet-cement;
    }
    &:active {
      background: $blue;
      color: $white;
    }
  }

  &.pls-btn--size-3 {
    padding: 16px 64px 16px 64px;
    height: auto;
    font-size: 24px;
  }

  &.pls-btn--pathways {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 16px !important;
  }


  &.pls-btn--transparent {
    color: $white;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:hover, &:active, &:visited {
      background: none;
      border: none;
      color: $white;
    }
  }

  &.pls-btn--muted  {
    opacity: .2;
  }

  &.pls-btn--try-it-now {
      color: white;
      font-size: 20px !important;
      padding: 16px 56px 16px 56px;
  }

  &.pls-btn--try-it-now-new {
    color: white;
    border-radius: 24px;
    font-size: 20px !important;
    padding: 16px 72px 16px 72px;
    position: relative;
    bottom: -24px;
    text-transform: none;
  }

  &.pls-btn--twitter {
    background-color: $twitter-blue;
    color: $white;

    &:hover, &:active, &:visited {
      color: $white;
    }
  }

  &.pls-btn--white {
    background: $white;
    color: $blue;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .65;
    background-color: $blue-steel;
    color: $deep-sea;
    box-shadow: none;
  }

  .input-group-btn & {
    height: 48px;
    box-shadow: none;

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.pulsar{
  position: relative;

  &:before {
    content: '';
    border: 2px solid $blue;
    background: transparent;
    height: 100%;
    width: 100%;
    animation: pulse 1.7s ease-out;
    animation-iteration-count: infinite;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 4px;
  }
}

.pls-btn--browse-list-item {
   height: 32px;
   font-family: $opensans;
   font-size: 14px;
   padding: 4px;
   margin-top: -4px;
   line-height: 24px;
   &:hover {
     background: white;
     color: $blue;
   }
}
.icon-circle--picmonic {
  width: 48px;
  height: 48px;
  background-color: $blue;
  box-shadow: 0 2px 6px 0 rgba(39, 43, 51, 0.2);
  border-radius: 50%;
  color: $black;
  font-size: 28px;
  line-height: 48px;
  margin: 8px;
  cursor: pointer;
}
