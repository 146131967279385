.join-the-team-bg {
  background-color: $deep-sea;
  background: url("../img/www/jobs-header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 768px) {
  .job-block {
    max-width: 400px;
  }
}