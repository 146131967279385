// Colors
// Add new variable and remember to add to the list at the bottom
$abyss: #212329;
$android-green: #A4C639;
$arctic: #E6F8FF;
$battleship-grey: #616B80;
$black: #000;
$blood-red: rgb(255,17,3);
$salmon: #F37979;
$blue: #00B6FF;
$blue-steel: #616B80;
$brown-steel: #807160;
$champagne: #FFF3E6;
$charcoal-grey: #353941;
$cloudy: #F5F5F5;
$cloudier: #F2F2F2;
$dark-blue: #09759C;
$dark-grey: #737477;
$deep-sea: #323A4D;
$dry-cement: #DBDBDB;
$dusk: #7A8499;
$facebook-blue: #3B579D;
$green: #67C25E;
$grey: #CECECE;
$jullians-eyes: #80DBFF;
$jullian-gray: #707070;
$light-grey: #EFEFEF;
$light-grey-80: #CCC;
$lighter-grey: #F9F9F9;
$le-tigre: #FF0085;
$magenta: rgb(255, 0, 122);
$magnum: #272B33;
$meekus: #18f291;
$mint: #91F2C7;
$military-blue: #272B34;
$mugatu: #F21818;
$orange: #FF9319;
$osmosis: #5CB85C;
$papaya: #FFF3E6;
$peach: #FFC380;
$pink: #FF3096;
$platinum: #E6E6E6;
$purple: rgb(114, 70, 221);
$red: #E9511C;
$soft-pink: rgb(196, 113, 249);
$success-green: #6EC02A;
$sunburned-jaime: #F27979;
$twitter-blue: #55ACEE;
$wet-cement: #AAACB3;
$white: #FFF;
$white-blue: #E0F3FB;
$yellow: rgb(209, 177, 0);

$beta-red: $mugatu;
$error-red: $mugatu;

$colors: (
  'abyss': $abyss,
  'android-green': $android-green,
  'arctic': $arctic,
  'beta-red': $beta-red,
  'battleship-grey': $battleship-grey,
  'black': $black,
  'blood-red': $blood-red,
  'blue': $blue,
  'blue-steel': $blue-steel,
  'brown-steel': $brown-steel,
  'champagne': $champagne,
  'charcoal-grey': $charcoal-grey,
  'cloudy': $cloudy,
  'cloudier': $cloudier,
  'dark-blue': $dark-blue,
  'dark-grey': $dark-grey,
  'dusk': $dusk,
  'light-grey': $light-grey,
  'light-grey-80': $light-grey-80,
  'lighter-grey': $lighter-grey,
  'deep-sea': $deep-sea,
  'dry-cement': $dry-cement,
  'error-red': $error-red,
  'facebook-blue': $facebook-blue,
  'green': $green,
  'grey': $grey,
  'jullians-eyes': $jullians-eyes,
  'jullian-gray': $jullian-gray,
  'le-tigre': $le-tigre,
  'magenta': $magenta,
  'magnum': $magnum,
  'meekus': $meekus,
  'mint': $mint,
  'military-blue': $military-blue,
  'mugatu': $mugatu,
  'orange': $orange,
  'osmosis': $osmosis,
  'papaya': $papaya,
  'peach': $peach,
  'pink': $pink,
  'platinum': $platinum,
  'purple': $purple,
  'red': $red,
  'salmon': $salmon,
  'soft-pink': $soft-pink,
  'success-green': $success-green,
  'sunburned-jaime': $sunburned-jaime,
  'twitter-blue': $twitter-blue,
  'wet-cement': $wet-cement,
  'white': $white,
  'white-blue': $white-blue,
  'yellow': $yellow,
);

@each $name, $value in $colors {
  .pls-#{$name} {
    color: #{$value} !important;
  }

  .bg-pls-#{$name} {
    background: #{$value} !important;
  }

  .pls-border-#{$name} {
    border: solid 2px #{$value} !important;
  }

  .pls-btn--lighten-#{$name} {
    background: #{$value} !important;
    transition: all .35s;
    &:hover {
      background: lighten($value, 12%) !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }

  .pls-btn--outline-#{$name} {
    border: 2px solid #{$value} !important;
    color: #{$value} !important;
    background: transparent !important;
    transition: background .35s, box-shadow .35s;

    &:hover {
      @if $name != 'white' {
        background: #{$value} !important;
      } @else {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      }
      color: $white !important;
    }
  }

  .border-pls-#{$name} {
    border: 1px solid #{$value} !important;
  }

  .divider-pls-#{$name} {
    border-bottom: 1px solid #{$value} !important;
  }

  .shadow-pls-#{$name} {
    box-shadow: 0 2px 0 0 #{$value} !important;
  }

  .hover-pls-#{$name}:hover {
    background: #{$value} !important;
  }
}


.pls-gradient-sunrise {
  background-image: linear-gradient(93deg, #ffbf19, $orange);
}

.pls-gradient-peach {
  background-image: linear-gradient(101deg, #fdfdef, #fdfcef 16%, #fdfcef 18%, #fdfcef 21%, #fdfcef 22%, #fdfbef 30%, #f4e7e6);
}

.pls-gradient-sunlight {
  background-image: linear-gradient(137deg, #fdfdef, #f4e7e6);
}

.pls-gradient-steel {
  background-image: linear-gradient(121deg, #616b80, #323a4d);
}