/*
 * Provide a hover effect for non-touch devices, 
 * Adds styles via @content
 */

@mixin hover-pls() {
    .no-touch &:hover { // desktop/pointer. no touch detected. 
        @content;
    }

    .touch &:active { // mobile/touch. 
        @content;
    }
}

@mixin focus-pls() {
    .no-touch &:focus {
        @content;
    }

    .touch &:active {
        @content;
    }
}


