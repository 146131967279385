.footer {
  a {
    text-decoration: underline;
  }

  .description {
    max-width: 250px;
    margin: auto;
  }

  .connect-icons-wrapper {
    width: 182px;
    margin: 0 auto;

    div {
        display: inline-block;
        margin: 0 !important;
    }
    img {
        height: 48px;
    }
  }
}