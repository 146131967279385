.bb-pricing-header {
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.bb-pricing-box {
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 12px 0 #ffc380;
  min-width: 190px;
  max-width: 512px;
  width: 100%;

  margin: auto;

  table {
    width: 300px;
    margin: auto;

    .strike {
      text-decoration: line-through;
    }

    td {
      padding: 2px;
    }

    .bottom-border {
      border-bottom: 2px solid #AAACB3;
      padding-top: 8px;
    }

    @media (max-width: 360px) {
      .text-right {
        text-align: left !important;
      }
    }
  }
}

.bb-buy-now-cta {
  display: block;
  max-width: 480px;
  margin: auto !important;
}

.bb-video {
  width:100%;
  max-width: 700px;
}

.bb-related-img {
  width:100%;max-width:562px;
}