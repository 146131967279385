.pls-tag-item {
    margin: 13px 5px;
    padding: 0 5px;
    display: inline-block;
    float: left;
    font: 14px 'Helvetica Neue',Helvetica,Arial,sans-serif;
    height: 26px;
    line-height: 25px;
    border: 1px solid #acacac;
    border-radius: 3px;
    background: -webkit-linear-gradient(top, #f0f9ff 0, #cbebff 47%, #a1dbff 100%);
    background: linear-gradient(to bottom, #f0f9ff 0, #cbebff 47%, #a1dbff 100%);

    .remove-button {
        margin: 0 0 0 5px;
        padding: 0;
        border: none;
        background: 0 0;
        cursor: pointer;
        vertical-align: middle;
        font: 700 16px Arial,sans-serif;
        color: #585858;
    }
}