body[modality="keyboard"] {
  a:focus, a:focus * { text-decoration: underline !important; }
  button:focus, button:focus * { text-decoration: underline !important; }
  .learn h3:focus, button:focus * { text-decoration: underline !important; }
  a:focus, div:focus, h1:focus, h2:focus, h3:focus, h4:focus, span:focus {
    outline: -webkit-focus-ring-color auto 4px !important;
  }
  a.dropdown-toggle:focus, li:focus {
    outline: -webkit-focus-ring-color auto 4px !important;
  }
}
a:focus, div:focus, h1:focus, h2:focus, h3:focus, h4:focus, span:focus, li:focus {
  outline: none !important;
}
