
body.publicuser.quiz, body.publicuser.onboarding {
  .quiz-body-wrapper, div[ui-view=template], .main-content {
    min-height: calc(100vh - 15px);
  }
}

@media (max-width: 768px) {
  body.publicuser.quiz, body.publicuser.onboarding {
    .quiz-body-wrapper, div[ui-view=template], .main-content {
      min-height: calc(100vh - 23px) !important;
    }
  }
}

body.publicuser.auth .toggle-wrapper {
  background: rgba($cloudy, .32);
}

body.onboarding {
  height: auto !important;

  .trial-status.max-width {
    max-width: 480px;
  }

  .semi-transparent {
    opacity: 0.2;
  }

  .onboarding-width {
    width: 288px;
    margin: auto;

    .logo-nav-icon {
      margin-top: -6px;
    }
  }

  .onboarding-section {
    padding: 8px;
    border-radius: 4px;

    span {
      margin-top: 1px;
    }
  }

  .onboarding-check {
    font-size: 18px;
    margin-top: 2px;
    transition: all .2s;

    &.bounce {
      transform: scale(18);
      transition: all .5s;
      opacity: 0.5;
    }
  }

  .onboarding-icon {
    font-size: 18px;
  }

  .onboarding-description {
    font-family: 'Open Sans';
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }

  .onboarding-number {
    font-family: 'Open Sans';
    font-size: 40px;
    line-height: 44px;
    font-weight: bold;
  }

  .circle-number {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    text-align: center;
    margin-top: -2px;
    font-size: 12px;
    padding-top: 3px;
    font-family: $opensans;
    font-weight: lighter;

    &--white {
      border: 1px solid #ffffff;
    }
  }

  .pls-btn:hover {
    .circle-number--white {
      border: 1px solid $blue;
    }
  }

}