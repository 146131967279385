.pls-typeahead {
  position: relative;

  &--dark {
    i {
      color: white;
    }
  }

  i {
    position: absolute;
    top: calc(50% - 8px);
    right: 16px;
  }

  &__results {
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    width: 100%;
    background: #FFF;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid $cloudy;
    z-index: 1;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    display: none;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    ul {
      margin: 0px;
      list-style-type: none;
      padding: 0px;

      li {
        padding: 8px 16px;
        color: $deep-sea;

        &.highlighted {
          font-weight: bolder;
          background-color: $blue;
          color: $white;
          @include hover-pls() {
            background-color: $blue;
          }
        }

        @include hover-pls() {
          cursor: pointer;
          background: $arctic;
        }
      }
    }
  }

  &--focused {
    .pls-typeahead__results {
      display: block;
    }
  }
}