.segmented-btns .pls-btn, .segmented-btns .pls-input {
  border: 1px solid $blue;
  background-color: transparent;
  color: $blue;
  font-size: 14px !important;
  font-weight: 600;

  &:hover {
    background-color: $blue;
    color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &.selected {
    background-color: $jullians-eyes;
    color: $battleship-grey;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-color: transparent;
  }
}
.segmented-btns .pls-input {
  width: auto;
  height: 36px;
  padding: 8px 32px 8px 16px;
}
.segmented-btns select {
  background: #272B33 url("../img/select-arrow-blue.svg") no-repeat 90% 50%;
  &:hover {
    background: #272B33 url("../img/select-arrow-white.svg") no-repeat 90% 50%;
  }
}