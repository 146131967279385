  .pricing-box-wrapper {
    border-radius: 4px;
    background-color: $white;
    border: solid 1px $wet-cement;
    cursor: pointer;
    font-size: 11px;
    color: $dusk;
    padding: 0 6px 6px 6px;
    float: left;
    width: 100%;

    @media (max-width: 768px) {
      .pricing-box-bullet-item:last-child {
        margin-bottom: 16px;
      }
    }
  }

  @media (min-width: 769px) and (max-width: 892px) {
    .pricing-container {
      width: 100% !important;
    }
  }

  .pricing-pad-lg {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (max-width: 991px) {
    .pricing-pad-lg {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  .pricing-box-wrapper {
    background-color: $arctic;
    border: solid 1px $blue;
    overflow: hidden;

    &--free {
      background-color: $cloudy;
      border: solid 1px $dry-cement;
    }

    &--best {
      background-color: $champagne;
      border: solid 1px $orange;
    }

    &--transparent {
      background-color: transparent;
      border: solid 1px transparent;
      cursor: default;
    }
  }

  .pricing-plan-price {
    color: $black;
    font-weight: 700 !important;
    min-width: 112px;

    &-nursing-premium {
      color: #00B6FF !important;
      font-weight: bold !important;
      padding-top: 2px !important;
    }
  }

  .pricing-plan-price sup.dollar, &-plan-price-nursing-premium sup.dollar {
    top: -1em;
    font-size: 45%;
    font-weight: 100;
  }

  .pricing-plan-price sup.cents, &-plan-price-nursing-premium sup.cents {
    top: -1.1em;
    font-size: 45%;
  }

  .pricing-plan-price sub.unit, &-plan-price-nursing-premium sub.unit {
    top: 0.2em;
    left: -17px;
    font-size: 16px;
    font-weight: normal;
  }

  .pricing-plan-cta {
    font-size: 16px;
    font-weight: 100 !important;
  }

  .pricing-premium-plan-header {
    border-radius: 8px 8px 0 0;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
  }

  .pricing-block-expiration-date {
    font-size: 10px !important;
    line-height: 12px;

    &--transparent {
      color: transparent;
    }
  }

  .pricing-block-hidden-cta {
    opacity:0;

    button {
      cursor: default;
    }
  }

  .pricing-block-header {
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    .pricing-block-header {
      font-size: 24px;
      line-height: 32px;
    }

    .pricing-block-sub-header {
      font-size: 14px;
    }
  }

  .pricing-badge {
    width: 72px;
    height: 72px;
    border-radius: 4px;
    background-color: rgba(255, 249, 0, 0.6);
    right: -36px;
    top: -36px;
    position: absolute;
    transform: rotate(-315deg);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 128px;
    letter-spacing: normal;
    text-align: center;
    color: $black;
    font-size: 12px;
  }

/** PRICING PAGE **/
body.pricing {
  padding-top: 64px;

  .navbar-nav > li > .dropdown-menu > li > a {
    white-space: nowrap;
  }

  .success-special-wrapper h1 {
    font-family: Raleway;
    font-size: 54px;
    line-height: 56px;
    padding-top: 24px;
    padding-bottom: 0px;
  }

  .navigation .dropdown-menu > li > a:focus {
    background-color: transparent !important;
  }

  .pls-h2, .success-special-wrapper h2 {
    font-family: Raleway;
    font-size: 36px;
    line-height: 48px;
    padding-top: 24px;
    padding-bottom: 0px;
  }

  .btn-flat-orange {
    background: $orange !important;
  }

  .main-sub-title {
    font-size: 24px;
    color: #616b80;
  }

  .panel-group .panel {
    margin-bottom: 0;
    overflow: hidden;
    border-bottom: 2px solid #ddd;
  }

  .panel-group .panel-heading + .panel-collapse .panel-body {
    border: none;
  }

  .panel-default > .panel-heading {
    background: none;
  }

  .panel-group .panel-heading h4.panel-title {
    padding-top: 0px;
  }

  .panel-group .panel {
    border-radius: 0px;
  }

  .panel {
    margin-bottom: 20px;
    background-color: white;
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .panel-body div {
    font-size: 14px;
  }

  .panel-body div a {
    text-decoration: underline;
    color: #00B6FF;
  }

  .center-list-items {
    position: relative;
    right: -5%;
  }

  button.btn-flat-orange {
    color: #272B33 !important;
  }

  .nursing-special-wrapper h2.nursing-special-text {
    font-size: 32px;
    line-height: 36px;
    font-style: italic;
  }
}

body.pricing {
  overflow-x: hidden !important;
  max-width: 100% !important;
  position: relative !important;
}

.nclex-badge {
  margin: 0 auto;
  text-align: center;
}

.nclex-badge img:first-child {
  width: 78px;
  height: 80px;
  margin: 0 auto;
}

.pance-badge {
  margin: 0 auto;
  margin-top: -40px;
  text-align: center;
}

.pance-badge img:first-child {
  width: 78px;
  height: 80px;
  margin: 0 auto;
}

.nclex-padding-bottom {
  padding-bottom: 40px !important;
}

.pricing-cta-wrapper {
  padding-bottom: 15px;
}

.pricing-lead-select-wrapper {
  position: relative;
  float: left;
  left: 50%;
}

.pricing-lead-select-content {
  position: relative;
  float: left;
  left: -50%;
}

.pricing-lead-select {
  font-size: 54px;
  font-style: italic;
  color: #AAACB3;
}

.offering-list p {
  display: flex;
}

/** PRICING TABLE **/
.pricing-premium-col {
  margin-top: -34px;
}

sup.cents.pls-wet-cement, sup.dollar.pls-wet-cement {
  color: #aaacb3 !important;
}

.pricing-line-through {
  width: 60%;
  border: 2px solid $black;
  border-radius: 8px;
  position: relative;
  top: 28px;
  left: 20%;
  margin-top: -4px;
}

.nursing-special-wrapper {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .nursing-special-wrapper {
    margin-top: 25px;
  }
}

.nursing-special-wrapper img {
  margin: 0 auto;
}

.nursing-special-wrapper h3 {
  font-style: italic;
}

.nursing-special-wrapper .nursing-special-nclex-badge {
  margin: 0 auto;
  margin-top: 12px;
}

@media (max-width: 1280px) {
  body.pricing .center-list-items {
    position: relative;
    right: -8%;
  }
}

@media (max-width: 767px) {
  .pricing-premium-col {
    margin-top: 0px;
  }
}

.angel {
  max-height: 400px;
  position: relative;
  top: 0;
  left: 0;
}

.fly-away {
  -webkit-animation: fly-off 5s;
  -moz-animation: fly-off 5s;
  -ms-animation: fly-off 5s;
  -o-animation: fly-off 5s;
}

@-webkit-keyframes fly-off {
  0%, 100% {
    top: 0;
  }
  98% {
    top: -1200px;
    left: -100px;
  }
  99% {
    top: -9999px;
  }
}

@-moz-keyframes fly-off {
  0%, 100% {
    top: 0;
  }
  98% {
    top: -1200px;
    left: -100px;
  }
  99% {
    top: -9999px;
  }
}

@-ms-keyframes fly-off {
  0%, 100% {
    top: 0;
  }
  98% {
    top: -1200px;
    left: -100px;
  }
  99% {
    top: -9999px;
  }
}

@-o-keyframes fly-off {
  0%, 100% {
    top: 0;
  }
  98% {
    top: -1200px;
    left: -100px;
  }
  99% {
    top: -9999px;
  }
}

.nursing-mini-divider {
  width: 960px;
  margin: 0 auto;
  border-bottom: 2px solid #616b80;
  padding-top: 15px;
  margin-bottom: 10px;
}

.pricing-offering-numbers {
  font-family: 'Open Sans';
  font-size: 30px;
  color: #00B6FF;
}

.med-body-header-light {
  font-weight: 400;
}

.med-body-header {
  font-family: Raleway;
  font-size: 24px;
  line-height: 1.3;
  color: #80dbff;
}

.med-body-text {
  font-family: Raleway;
  font-size: 16px;
  line-height: 1.5;
  color: #dbdbdb;
}

.med-body-scholar-cartoon-img {
  max-width: 112px;
  max-height: 350px;
}

.med-body-exams1-header {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .med-body-exams1-header {
    margin-top: 0px;
  }
}

@media (max-width: 512px) {
  .med-body-header {
    font-size: 20px;
  }

  .choose-plan {
    font-size: 35px;
  }

  .med-body-header-light {
    font-size: 24px !important;
  }
}

@media (max-width: 767px) {
  html, body.pricing {
    overflow-x: hidden;
  }
}

.step-survival {
  // step-survival-banner
  &-banner {
    background-color: #e6f8ff;
    box-shadow: 0 2px 0 0 #80dbff, 0 -2px 0 0 #80dbff;
    font-family: Raleway;
    font-size: 16px;
    line-height: 1.5;
    color: #272b33;
    padding: 20px 20px;
    -webkit-touch-callout: default !important;
    user-select: auto !important;
    .small {
      font-size: 14px;
    }
  }

  &-banner span, &-header span {
    color: #00b6ff;
  }

  // step-survival-box
  &-box {
    background-color: #f5f5f5;
    color: #616b80;
    font-family: 'Open Sans';
    font-size: 16px;
    padding-top: 64px;
    padding-left: 88px;
    margin-top: 25px;
    -webkit-touch-callout: default !important;
    user-select: auto !important;

    .ol-header {
      font-family: Raleway;
      font-size: 23px;
      line-height: 1.4;
      color: #00b6ff;
    }

    ol {
      font-size: 12px;
      line-height: 20px;
      padding-left: 20px;
      padding-top: 10px;
      padding-right: 15px;

      b {
        color: #272b33;
      }
    }

    .step-survival-banner {
      box-shadow: 0 2px 0 0 #80dbff;
      margin-left: -88px;
    }
  }
  // step-survival-header
  &-header {
    font-family: Raleway;
    font-size: 34px;
    line-height: 1.2;
    color: #323a4d;
    font-weight: bold;
  }

  // step-survival-exclusive
  &-exclusive {
    font-family: Raleway;
    font-size: 16px;
    color: #00b6ff;
  }
}

.display-table {
  display: table;
}

.pricing-centered {
  float: none;
  margin: auto;
}

.pricing-slanted-header{
  margin-bottom: 200px;
}

.laptop-container{
  position: relative;
  width: 470px;
  height: 100px;
  margin: 0px auto;
}
.laptop-top{
  width: 410px;
  height: 270px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(to top, #d0d0d0, rgba(0, 0, 0, 0)), linear-gradient(#eeeeee, #eeeeee);
  position: relative;
  padding: 20px;
  margin: 0 0 0 30px;
  box-shadow: 0 0 60px rgba(0,0,0,0.2);
}
.laptop-screen{
  width: 370px;
  height: 240px;
  border: 1px solid #000;
  padding: 15px;
  text-align: left;
  background: #FFF;
  position: relative;
  z-index: 0;
  font-size: 25.5px;
  line-height: 1.5;
}
.laptop-bottom{
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 40%;
  border-bottom-right-radius: 40%;
  width: 470px;
  height: 13px;
  background: linear-gradient(to top, #d0d0d0, #e7e7e7);
  position: relative;
  z-index: 1;
}

.pricing-pa-badge{
  max-width: 100%;
  height: auto;
}

.pathway-icon-frame{
  width: 90%;
  height: auto;
  border-radius: 50%;
  background: #616B80;
  margin: 0 auto;
}

.pathway-icon-frame img{
  width: 80%;
  margin: 10%;
}
.video-play-btn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #00B6FF;
  text-align: center;
  cursor: pointer;

  i{
    color: #FFF;
    font-size: 20px;
    margin-left: 3px;
    line-height: 40px;
  }
}

@media (max-width: 768px){
  .video-play-btn{
    width: 170px;
    height: 170px;
    margin: 45% auto 0 auto;

    i{
      font-size: 90px;
      margin-left: 24px;
      line-height: 170px;
    }
  }
  .iphone-wrapper{
    font-size: 10px;
    margin: 20px auto;
    width: 22.8125em;
    height: 260px;
  }
}

.video-modal-close-icon {
  top: 7px;
  right: 10px;
  position: absolute;
}

.video-modal-box {
  margin: 16px
}

.bg-student-meekus {
  background: url("../img/bg-student-meekus.jpg");
  background-color: $meekus;
  background-repeat: no-repeat;
  background-size: cover;
}

.nursing-np-characters-img {
  max-width: 100%;
  height: auto;
  max-height: 250px;
}

.nursing-np-characters-gif {
  max-width: 100%;
  height: auto;
  max-height: 400px;
}

.heading__nursing-lpn {
  background-color: $deep-sea;
  background: linear-gradient(to left, rgba(97, 106, 128, 0.8), $deep-sea 100%), url("../img/www/pricing/nursing-lpn/nursing-lpn-background.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  min-height: 576px;
  padding-right: 16px;
  padding-left: 16px;

}

.heading__beta {
  background-color: $deep-sea;
  background: linear-gradient(to bottom, rgba(50, 58, 77, 0.4), $deep-sea), url("../img/bg-student.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  min-height: 576px;
  padding-right: 16px;
  padding-left: 16px;

}