.toggle-wrapper{
  width: 40px;
  height: 14px;
  border-radius: 8px;
  background: rgba($deep-sea, .32);
  position: relative;
  margin-top: 8px;
  cursor: pointer;
  transition: background 0.6s;
}

ui-toggle[disabled="disabled"], .slider-wrapper[disabled="disabled"]{
  pointer-events: none;
}

.toggle-wrapper .toggle-handle{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $cloudy;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: -45%;
  transition: left 0.6s, background 0.3s;
}

.toggle-wrapper:hover, .toggle-wrapper:hover .toggle-handle{
  background: $white;
}

.toggle-wrapper.active, .toggle-wrapper.active .toggle-handle{
  background: $blue;
}

.toggle-wrapper.active {
  background: rgba($blue, .4);
}

.toggle-wrapper.active:hover, .toggle-wrapper.active:hover .toggle-handle{
  background: $jullians-eyes;
}

.toggle-wrapper.active .toggle-handle{
  left: calc(100% - 24px);
}

.slider-wrapper{
  background: #1C1C1C;
  border-radius: 5px;
  border: 2px solid #1C1C1C;
  cursor: pointer;
}