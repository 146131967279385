body{
  &.login, &.register, &.auth{
    font-size: 14px;

    .onboarding {
      font-size: 16px;
    }

    .popover {
      color: $black;
    }

    .main-content{
      background: $charcoal-grey;
    }

    .fade-in{
      &.ng-enter {
        transition: all 0.5s;
        .fade-in__content{
          transition: all 0.5s;
          opacity: 0;
        }

        &.ng-enter-active {
          .fade-in__content{
            opacity: 1;
          }
        }
      }
    }
  }
}

body.apphome {
  .dropdown-menu{
    width: calc(100% - 30px);
    max-height: 200px;
    overflow-y: auto;

    li a{
      color: $magnum !important;
    }
  }
}

.divider--light {
  margin: 8px auto;
  border-bottom: 1px solid #AAACB3;
}

.auth-tabs {
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba($black, 0.5);

  a:not(.pls-btn){
    color: $blue;
  }

  input[type="text"].pls-input, input[type="password"].pls-input, input[type="email"].pls-input {
    height:40px;
    margin-bottom:3px;
    padding: 10px 16px;
  }

  .pls-btn--facebook {
    height: 48px;
    line-height: 32px !important;
    text-transform: none;
    font-weight: normal;

    .icon-facebook {
      float: left;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 24px;
      padding: 8px 0;
      padding-right: 12px;
      margin-top: -4px;
    }
  }

  .divider {
    margin: 10px -15px;
    border-bottom-color: $blue-steel; 
  }
}
