.pls-progress-bar{
  display: block;

  .progress {
    float: left;
    height: 0.5em;
    opacity: 0.9;
    border-radius: 4px;
    background-color: $blue-steel;

    &.completed {
      background-color: $meekus;
    }

    &.no-border-radius-right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.no-border-radius-left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &--default {
    height: 0.5em;
    opacity: 0.9;
    border-radius: 4px;
    background-color: $dry-cement;
  }

  &--green, &--meekus {
    height: 4px;
    background-color: $dry-cement;
    border-radius: 2px;

    .progress {
      height: 4px;
      border-radius: 2px;
      background-color: $meekus;
    }
  }

  &--group-buy {
    height: 8px;
    background-color: $white;
    border-radius: 4px;

    .progress {
      height: 8px;
      border-radius: 4px;
      background-color: $blue;
    }
  }
}