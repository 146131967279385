.pls-tabs{
  clear: both;

  &__tab{
    font-size: 16px;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    padding: 0.7em 1em;
    cursor: pointer;
    display: inline-block;
    foat: left;

    &--selected{
      color: $blue;
      background: $cloudy;
    }
  }
}

.auth-tabs{
  &__tab{
    color: $white;
    font-size: 16px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    background: $magnum;

    &:first-of-type{
      border-top-left-radius: 4px;
    }

    &:last-of-type{
      border-top-right-radius: 4px;
    }

    &--selected{
      background: $deep-sea;
      color: $blue;
    }
  }

  &__content{
    padding-top: 8px;
    padding-bottom: 8px;
    background: $deep-sea;
    border-radius: 4px;

    &--selected{
      display: block;
      background: $deep-sea;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}