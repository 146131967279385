body.modal-open{
  .intercom-launcher-frame {
    visibility: hidden !important;
  }

  #intercom-container {
    display: none !important;
  }

  .intercom-launcher-badge-frame {
    visibility: hidden !important;
  }
}

.navigation.navbar-collapse {
  max-height: calc(100vh - 63px);
}
.nav {
  background: rgba($deep-sea, 0.94);
  margin-top: 0;

  #nav-submenu-cta {
    display: none;
  }

  a.dropdown-toggle{
    &:after {
      content: "\f078";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: 22px;
    }

    &.open:after{
      content: "\f078";
    }
  }
}

.navbar-header {
  height: ($navbar-public-height - 1);  
}


.navbar {
  background: rgba($deep-sea, 0.94);
  border: none;
  border-radius: 0px;
  height: $navbar_public_height;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: $zindex_navbar;

  .dropdown-menu > li > a {
    padding-right: 32px;
    text-overflow: initial;
    min-width: 199px;
  }

  li{

    &.selected, &.open > a{
      color: $jullians-eyes;
      position: relative;

    }

    a {
      color: $white;
      font-weight: normal;
      text-transform: uppercase;
      display: block;
      font-size: 14px;
      line-height: 1.43;
      -webkit-font-smoothing: antialiased;
      background: none !important;

      &.nav-menu-item{
        margin-top: 0px;
        padding: 0px 15px;
        height: ($navbar_public_height - 4);
        line-height: $navbar_public_height;
      }

        

      .caret {
        display:none;
      }
    }

    .dropdown-menu{
      background: rgba($deep-sea, 0.95);
      box-shadow: none;
      color: $dry-cement;
      min-width: 199px;
      margin-top: 4px;
      border: none;

      a:hover, a:focus {
        color: $blue;
      }

      li.open{
        background-color: $magnum;

        li{
          padding-left: 16px;
        }
      }     
    }
  }

  .text-logo img{
    height: 32px;
  }
  
  .navbar-buy-now {
    padding: 4px 6px;
    height: 36px;
    color: $white;
    border: none;
    margin-top: 14px;
    margin-right: 16px;
    float: right;
    line-height: 36px;
    padding: 0 15px;
    background: $orange !important;
    border-radius: 4px;

    &:focus, &:active {
      outline: 0;
    }

    @media (max-width: 380px) {
      margin-right: 8px;
    }
  }

  .navbar-toggle {
    padding: 4px 6px;
    font-size: 16px;
    color: $white;
    border: none;
    margin-top: 20px;
    margin-right: 16px;

    &:focus, &:active {
      outline: 0;
    }

    .icon-bar {
      background-color: $wet-cement;
    }
  }

  .login-menu-btn a {
    line-height: 36px;
    margin-top: 14px;
    padding: 0 15px;
  }

  .login-menu-btn.login-menu-btn-new a {
    color: white !important;
    border: none !important;
    font-weight: bold;
    background-image: linear-gradient(93deg, #ffbf19, $orange) !important;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 768px) and (max-width:814px) {
  .navbar li a.nav-menu-item {
    padding: 0 9px;
  }
}

@media (min-width: 768px) {

  .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  .nav {
    background-color: transparent;

    a.dropdown-toggle:after {
      right: 11px;
    }

    #nav-submenu-cta {
      display: block;
      bottom: 16px;
      left: 50%;
      position: absolute;
      text-align:center;
      margin-left:-145px;

      .pls-btn {
        background: $blue-steel !important;
        padding: 8px 16px 8px 16px !important;
        color: $white;
        pointer-events: all;
        cursor: auto;
      }
    }

    & > .open, & > .nav-menu-item-wrapper:hover {
      border-bottom: 4px solid $jullians-eyes;
    }

    & > .dropdown {

      .dropdown > .dropdown-menu {
        position: static;
        background: transparent;
        border: none;
        float: none;
      }

      & > a {
        text-overflow: intial;

        &:after {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 4px dashed;
          border-top: 4px solid \9;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          position: static !important;
          margin-top: 0px !important;
          content: '' !important;
        }

        .caret {
          display: inline-block;
        }
      }

      &.open, &:hover {

        &.subjects-nav > .dropdown-menu {
          display:flex;
          height: 592px;
          margin-top: 0;
        }
      }

      &.subjects-nav a:after {
        content: '' !important;
      }
    }

    .subjects-nav .dropdown-menu {
      width: 100%;
      flex-direction: column;
      flex-wrap:  wrap;
      align-items: stech;

      .dropdown {
        background-color: $blue-steel !important;
        padding: 5px 0 8px 0;
        flex: 0 0 auto;
        margin: 8px;
        border-radius: 4px;

        li {
          padding-left: 0 !important;
            a {
              pointer-events: all;
              cursor: pointer;
            }
        }
      }

      .dropdown-menu {
        display: block;
        position: static;
        border: none;
        margin: 0;
        padding: 0;

        a {
          font-weight: normal;
          font-size: 14px;
          color: $platinum;

          &:hover {
            color: $blue;
          }
        }
      }
    }

    & > li.subjects-nav {
      position: static;

      .dropdown-menu a {
        color: $white;
        font-weight: bold;
        font-size: 18px;
        pointer-events: none;
        cursor: auto;
      }
    }
  }

}