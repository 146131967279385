.heading__how-it-works {
  background-color: $deep-sea;
  background: linear-gradient(to left, rgba(97, 106, 128, 0.8), $deep-sea 100%), url("../img/www/how-it-works/how-it-works-heading.jpg") no-repeat;
  background-size: cover;
  background-position: right;
  min-height: 576px;
  padding-right: 16px;
  padding-left: 16px;
  margin: 0px;
  //reduce min-height for mobile too much padding, too little text otherwise.
  @media (max-width: 480px) {
    min-height: 0px;  
  }
}

.shadow-me-xerxes {
  box-shadow: #272B33 0 0 8px 0;
}

.hiw-checklist {
  display: flex;
  position: relative;
  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  &--inner {
    align-items: center;
    display: flex;
    flex: 1;
  }
  &--triangle {
    position: absolute;
    right: 0;
    top: calc(50% - 16px);
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-right: 32px solid $blue;
  }
  .flex-item {
    line-height: 2;
  }
}

@media(max-width: 768px) {
  .hiw-checklist {
    text-align: center;
    &--container {
      display: block !important;
    }
    &--triangle {
      display: none;
    }
  }
}

.modal-header-title {
  font-size: 18px;
  line-height: 32px!important;
}

@media (min-width: 401px) and (max-width: 475px) {
  .modal-header-title {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .modal-header-title {
    font-size: 12px;
  }
}
