body.browse.browse--new.onboarding {
  background-color: $charcoal-grey;

  .main-content {
    width: auto;
    margin-left: 0;
  }
}

.onboarding {

  &-nav {
    display: flex;
    background-color: $charcoal-grey;
    align-items: center;

    @media (min-width: 480px) {
      &.ml8 {
        margin-left: 64px;
      }
    }

    .pls-progress-bar {
      display: inherit;

      &.border-none-right {
        .pls-progress-bar--default {
          border-right: 1px solid $charcoal-grey;
        }

        .pls-progress-bar--default, .progress {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.border-none {
        .pls-progress-bar--default, .progress {
          border-radius: 0
        }
      }

      &.border-none-left {
        .pls-progress-bar--default {
          border-left: 1px solid $charcoal-grey;
        }

        .pls-progress-bar--default, .progress {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &--default {
        background-color: #606b80;
      }

      &--complete {
        box-shadow: 0 0 8px 0 $meekus;
      }

      .progress {
        float: none;
        background-color: $meekus;
        width: 0%;
        transition: width .5s;
      }
    }
  }

  &-logo img {
    height: 16px;
  }

  &-progress {
    flex-grow: 1;

    @media (max-width: 768px) {
      padding-top: 8px;
    }
  }
}