.modal-backdrop{
  &.below-nav{
    z-index: #{$zindex_navbar - 4} !important;
  }
  &.above-nav {
    z-index: #{$zindex_navbar + 1} !important;
  }

  &.prelearn-modal{
    height: calc(100% - 48px);
    top: 48px;
    opacity: 1;
    background-color: rgba($blue-steel, 0.96);
  }
}

.qotd-modal {
  .countdown {
    font-size: 14px;
    color: $wet-cement;
    margin-right: 10px;
    font-family: $opensans;
    display: block;
    text-align: center;
    margin-bottom: 8px;

    @media (min-width: 767px) {
      position: relative;
      top: 6px;
      display: inline;
    }

  }

  .modal-dialog {
    background: transparent;

    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 992px) {
      width: 970px;
    }

    @media (min-width: 1200px) {
      width: 1170px;
    }
  }

  .modal-content {
    background: transparent !important;
    box-shadow: none;
  }

  .modal-body {
    background: transparent !important;
    box-shadow: none;
  }
}

.modal{
  overflow-y: auto !important;
  -webkit-overflow-scrolling: auto !important;

  .close-btn {
    position: absolute;
    top: 8px;
    right: 16px;
  }

  &.prelearn-modal {
    height: calc(100% - 48px);
    top: 48px;
    transition: none;
    width: calc(100% - #{$navbar_width});
    left: $navbar_width;

    .btn {
      font-family: Raleway;
      font-size: 16px;
      line-height: 1.3;
      border-radius: 4px;
      font-weight: lighter;
    }

    .checkbox2 label {
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: lighter;
    }

    .checkbox2 label::before {
      background-color: transparent;
      border: 2px solid $dry-cement;
    }

    .checkbox2 label::after {
      color: $dry-cement;
    }

    .modal-dialog{
      transition: none;
    }

    .modal-content {
      background: transparent !important;
      box-shadow: none;
      border: none;
      text-align:center;

      .modal-body {
        background: transparent;
        box-shadow: none;
        border: none;
        text-align:center;
      }
    }
  }

  .modal-content{
    border: none;
    border-radius: 5px;

    .modal-body{
      background: $white;
    }
    .modal-footer{
      background: $white !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.below-nav{
    z-index: #{$zindex_navbar - 3} !important;
  }

  &.above-nav {
    z-index: #{$zindex_navbar + 2} !important;
  }
}

@media (max-width: 480px){
  .prelearn-modal{
    width: 100% !important;
    left: 0 !important;
  }
}

.school-modal {

  .pls-input {
    &.ng-invalid.ng-touched {
      border: solid 1px $wet-cement !important;
    }

    &.ng-invalid.ng-dirty {
      border: solid 1px $error-red !important;
    }
  }

  .dropdown-menu {
    max-width: 90%;
  }

  .half-width {
    width: 50%;
  }

  .school-badge {
    max-height: 320px;
  }

  .ui-tooltip.ui-tooltip--bottom {
    top: 33%;
  }

  .modal-body {
    min-height: 520px;

    .h2 {
      font-family: 'Open Sans';
      font-size: 24px;
    }
  }

  .school-modal-footer {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
}

.miniplayer-modal {
  .dropdown {
    position: relative;
    top: 0px !important;
  }
}

.badge-modal {
  .modal-content {
    .modal-body {
      h3 {
        font-size: 24px;
        font-family: Raleway, sans-serif;
        color: $white;
        margin-bottom: 24px;
        letter-spacing: 1.5pt;
      }
      font-size: 16px;
      letter-spacing: 1.5pt;
      font-family: OpenSans, sans-serif;
      background-color: $blue-steel !important;
      color: $white;
      border: 4px solid $blue;
    }
  }
}

.learn-light-modal, .upsell-modal, .learn-master-complete {
  height: 100%;
}

.learn-light-modal .modal-dialog, .upsell-modal .modal-dialog, .learn-master-complete .modal-dialog {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

@media (max-width: 768px) {
  .learn-light-modal .modal-dialog, .upsell-modal .modal-dialog, .learn-master-complete .modal-dialog {
    position: relative;
    top: 56px;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
  }
}

.community-fact-disclaimer-modal h2 {
  line-height: 1;
}

.learn-light-modal .modal-content {
  width: 600px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
  margin: 0px auto;
  h1 {
    line-height: 1;
  }
}

.miniplayer-wrapper .learn-image-wrapper, .miniplayer-modal .learn-image-wrapper {
  height: 400px;
  margin: auto;
  position: relative;
  z-index: 1;
}

body.quiz .miniplayer-wrapper .learn-image-wrapper {
  height: calc(100vh - 160px);
  max-height: 100%;
}

.dashboard .modal-header .close, .survey-modal .modal-header .close {
  margin-top: -8px !important;
}

@media (max-width: 768px) {
  .miniplayer-wrapper {
    height: calc(100% - 60px);

    .learn-image-wrapper {
      height: 225px;
    }
  }
  .miniplayer-modal {
    .learn-image-wrapper {
      height: 320px;
    }
    .modal-dialog {
      width: 100%;
      max-height: 100%;
      padding: 0;
    }
    .modal-content {
      min-height: 100%;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
  }
  .registration-cta-modal {
    .modal-dialog {
      width: 100%;
      max-height: 100%;
      padding: 0;
    }
    .modal-content {
      min-height: 100%;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
  }
  .full-screen-modal {
    .modal-dialog {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      top: 0;
    }
    .modal-content {
      min-height: 100%;
      border-radius: 0;
    }
    .modal-header {
      border-radius: 0;
    }
  }
  .quiz-answer-wrapper {
    padding-bottom: 2px !important;
  }
  .picmonic-thumbnail {
    .card-icon .icon-play {
      margin: 24% 0 0 10%;
    }
    .card-icon-pause .icon-pause {
      margin: 24% 3% 0 10%;
    }
  }
}

.miniplayer-modal {
  .btn {
    font-size: 24px;
    border-radius: 10px;
  }
  h2 {
    font-family: Raleway;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
  }
  .icon-close {
    font-size: 24px;
    cursor: pointer;
    float: right;
  }
}

.learn-master-complete {
  .modal-content {
    width: 450px;
    max-width: 100%;
    border: none;
    margin: 0px auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .modal-body {
    background: none;
  }
}

.learn-light-modal .modal-body {
  background: none;
}

.nursing-program-modal {
  .modal-content {
    border: none;
    margin-top: 10%;
  }
  .icon-close {
    font-size: 24px;
    cursor: pointer;
    float: right;
  }
  .modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
    h2 {
      font-family: Raleway;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.5;
      color: #ffffff;
    }
  }
  .modal-body {
    h2 {
      font-family: Raleway;
      font-size: 16px;
      line-height: 1.5;
      color: #616b80;
    }
    p {
      line-height: 40px;
      margin-left: 50px;
      font-size: 16px;
      color: #272b33;
    }
  }
  .program-button {
    border-radius: 6px;
    p {
      margin-bottom: 0px;
    }
  }
  .program-circle {
    background: #f5f5f5;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 16px;
    color: #272b33;
    line-height: 40px;
  }
}

@media (max-width: 320px) {
  .nursing-program-modal .modal-body p {
    font-size: 14px;
  }
}

.registration-cta-modal {
  .dropdown-menu {
    max-height: 150px;
  }

  input[type="text"].pls-input, input[type="password"].pls-input, input[type="email"].pls-input {
    height:42px;
    margin-bottom:3px;
    padding: 10px 16px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  font-family: Raleway;
  font-size: 14px;
  width: 420px;
  max-width: 100%;
  border: none;
  margin: 0px auto;
  .modal-content {
    border: none;
    border-radius: 5px;
    overflow: visible;
    box-shadow: 0 2px 4px 0 rgba(33, 35, 41, 0.2);
  }
  .modal-dialog {
    width: 100%;
  }
  .modal-header {
    background: $platinum;
    border: none;
    padding: 11px 15px;
    padding-top: 12px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .modal-title {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }
  }
  .modal-body {
    background: #F5F5F5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    p {
      line-height: 24px !important;
    }
  }
  .divider {
    border-bottom: 1px solid #aaacb3;
  }
  input {
    font-size: 16px;
    padding: 10px;
  }
  .sign-up {
    font-size: 16px;
    color: #FFFFFF;
  }
  button:focus {
    text-decoration: none !important;
    * {
      text-decoration: none !important;
    }
  }
  .pls-btn--facebook {
    height: 48px;
    line-height: 32px !important;
    text-transform: none;
    font-weight: normal;

    .icon-facebook {
      float: left;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 24px;
      padding: 8px 0;
      padding-right: 12px;
      margin-top: -4px;
    }
  }
  .btn-flat-facebook {
    .icon-facebook {
      font-size: 24px;
    }
    div {
      font-size: 16px;
      line-height: 28px;
    }
  }
  p.terms {
    color: #aaacb3;
    font-size: 10px;
    line-height: normal !important;
    padding-top: 4px;
  }
  .modal-header .close-modal {
    font-size: 24px;
    cursor: pointer;
    float: right;
    color: $wet-cement;
  }
}

.related-picmonics-modal {
  .modal-dialog {
    width: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .modal-content {
    width: 100%;
  }
}

@media(min-width: 480px) {
  .related-picmonics-modal .modal-dialog {
    margin-left: 72px;
    margin-right: 16px;
  }
}

@media (max-width: 768px) {
  .related-picmonics-modal .modal-dialog {
    width: calc(100% - 24px);
    height: 70%;
  }
  .learn-light-modal .modal-content {
    max-width: 100%;
  }
}

.editor-modal {
  top: 100px;
  .modal-title {
    font-family: Raleway, sans-serif;
  }
  .modal-body {
    font-family: OpenSans, sans-serif;
  }
}

.video-modal{
  .embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%;
  }

  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.registration-cta-modal {
    .segmented-btns .pls-btn, .segmented-btns .pls-input {
      border: 1px solid $blue;
      background-color: transparent;
      color: $blue;
      font-size: 14px !important;
      font-weight: 600;

      &:hover {
        background-color: $blue;
        color: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }

      &.selected {
        background-color: $jullians-eyes;
        color: $battleship-grey;
        box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        border-color: transparent;
      }
    }
    .segmented-btns .pls-input {
      width: auto;
      height: 36px;
      padding: 8px 32px 8px 16px;
    }
    .segmented-btns select {
      background: #272B33 url("../img/select-arrow-blue.svg") no-repeat 90% 50%;
      &:hover {
        background: #272B33 url("../img/select-arrow-white.svg") no-repeat 90% 50%;
      }
    }
  }