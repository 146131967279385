.box-shadow--wet-cement {
  box-shadow: 0 2px 0 0 $wet-cement;
  border-radius: 4px;
  border: solid 1px $wet-cement;
}

.paid-plan-icon {
  float: left;
  text-align: center;
  width: 64px;
  margin-top: 48px;
  margin-bottom: 48px;

  img {
    height: 64px;
  }
}

.plan-option-description {
  min-height: 72px;
}

.paid-plan-text {
  margin-top: 48px;
  margin-left: 88px;
}

@media(max-width: 768px) {
  .paid-plan-icon {
    float: none;
    width: 100%;
  }

  .paid-plan-text {
    margin-left: 0;
  }
}

.blue-bullet-list {
  font-size: 16px;
  list-style-image: url(../img/small-bullet.png);
  line-height: 32px;

  li {
    padding-left:15px;
    color: $battleship-grey;
  }
}

.by-students-for-students {
  background-image: url(../img/www/pricing/bg-students-laptop.jpg);
  background-size: cover;
  background-position: center;
}

body.pricing .panel-group .panel {
  background: none;
}

.cardlist-container{
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 0 0 #aaacb3;
  border: solid 1px #aaacb3;
  text-align: left;
  padding: 24px;
  line-height: 32px;
  font-size: 12px;
  color: $wet-cement;

  > div {
    border-bottom: 1px solid #dbdbdb;

      &:last-child {
        border: none;
      }
  }

  .arrow-left {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4.5px 0 4.5px 7px;
    border-color: transparent transparent transparent #000000;
    display: inline-block;
    position: relative;
    top: 11px;
  }

  .arrow-down {
    border-width: 7px 4.5px 0 4.5px;
    border-color: #000000 transparent transparent transparent;
  }

  .top-level {
    padding-left: 16px;
    padding-right: 130px;
    font-size: 16px;
    font-family: Raleway;
    font-weight: bold;
    color: $black;
  }

  .pathway {
    padding-left: 45px;
    padding-right: 32px;
    font-family: Raleway;
    font-size: 14px;
    color: $magnum;
  }

  .picmonic {
    padding-left: 40px;
    font-size: 12px;
    color: $black;
  }

  .odd-row {
    background-color: $cloudy;
  }
}