// Margin and Padding

.m-x-auto {
  margin-right: auto !important;
  margin-left:  auto !important;
}

.display {
  &-block { display: block; }
  &-inline { display: inline; }
  &-table-cell { display: table-cell; }
}

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $lengths in $spacers {
    $length-x:   map-get($lengths, x);
    $length-y:   map-get($lengths, y);

    .#{$abbrev}-a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
    .#{$abbrev}-t-#{$size} { #{$prop}-top:    $length-y !important; }
    .#{$abbrev}-r-#{$size} { #{$prop}-right:  $length-x !important; }
    .#{$abbrev}-b-#{$size} { #{$prop}-bottom: $length-y !important; }
    .#{$abbrev}-l-#{$size} { #{$prop}-left:   $length-x !important; }

    @media(min-width: $screen-sm-min) {
      .sm-#{$abbrev}-a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
      .sm-#{$abbrev}-t-#{$size} { #{$prop}-top:    $length-y !important; }
      .sm-#{$abbrev}-r-#{$size} { #{$prop}-right:  $length-x !important; }
      .sm-#{$abbrev}-b-#{$size} { #{$prop}-bottom: $length-y !important; }
      .sm-#{$abbrev}-l-#{$size} { #{$prop}-left:   $length-x !important; }
      .sm-pull-left { float: left; }
      .sm-pull-right { float: right; }
    }

    @media(min-width: $screen-md-min) {
      .md-#{$abbrev}-a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
      .md-#{$abbrev}-t-#{$size} { #{$prop}-top:    $length-y !important; }
      .md-#{$abbrev}-r-#{$size} { #{$prop}-right:  $length-x !important; }
      .md-#{$abbrev}-b-#{$size} { #{$prop}-bottom: $length-y !important; }
      .md-#{$abbrev}-l-#{$size} { #{$prop}-left:   $length-x !important; }
      .md-pull-left { float: left; }
      .md-pull-right { float: right; }
    }

    @media(min-width: $screen-lg-min) {
      .lg-#{$abbrev}-a-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
      .lg-#{$abbrev}-t-#{$size} { #{$prop}-top:    $length-y !important; }
      .lg-#{$abbrev}-r-#{$size} { #{$prop}-right:  $length-x !important; }
      .lg-#{$abbrev}-b-#{$size} { #{$prop}-bottom: $length-y !important; }
      .lg-#{$abbrev}-l-#{$size} { #{$prop}-left:   $length-x !important; }
      .lg-pull-left { float: left; }
      .lg-pull-right { float: right; }
    }

    // Axes
    .#{$abbrev}-x-#{$size} {
      #{$prop}-right:  $length-x !important;
      #{$prop}-left:   $length-x !important;
    }
    .#{$abbrev}-y-#{$size} {
      #{$prop}-top:    $length-y !important;
      #{$prop}-bottom: $length-y !important;
    }
  }
}
