.group-buy-join-block {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  > div {
    max-width: 461px;
    margin:0 auto;
  }
}

.group-buy-join-img {
  max-width: 320px;
}

.group-buy-join-time-box {
  max-width: 320px;
  margin: auto;
}