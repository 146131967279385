.presentations{
    .presentations-header {
        background: url(../img/presentations/road-trip.png) no-repeat top center;
        min-height:346px;

        span {
            font: bold italic 24px/32px $opensans;
            color: $deep-sea;
        }
    }

    .preso-search.pls-input {
        margin: 16px auto;
        max-width: 960px;
        display: block;
    }

    h1 {
        font: bold 48px/56px 'Cabin', sans-serif;
        color: $black;
    }

    table, h3{
        margin: 16px auto;
        width: 100%;
        max-width: 960px;
    }

    th {
        text-align: center;
        font: 24px 'Cabin', sans-serif;
        color: $black;
    }
    td {
        text-align: center;
        font: 14px $opensans;
        color: $dark-grey;
    }

    td + td, th + th {
        border-left: 1px solid #b9b9b9;
    }

    tr + tr, tbody {
        border-top: 1px solid #b9b9b9;
    }

    .presentation-block + .presentation-block {
        border-top: 1px solid #b9b9b9;
    }

    h2 {
        font: bold 32px/40px 'Cabin', sans-serif;
        color: $black;
    }

    .roadies span {
        font: italic 24px/32px $opensans;
        color: $battleship-grey;
        -webkit-font-smoothing: antialiased;
    }

    @media(max-width: 480px) {
        .presentations-header {
            h1, span {
                position: relative;
                top: -36px;
            }

            h2 {
                margin-top: 235px;
            }
        }

        .preso-search.pls-input {
            margin: 16px 16px;
            width: calc(100% - 32px);
        }
    }
}