// Import directives
@import "directives/pricing-banner";

// Import pages
@import "pages/about";
@import "pages/advocate";
@import "pages/bb";
@import "pages/blocks";
@import "pages/careers";
@import "pages/educators";
@import "pages/auth";
@import "../../../../angular/public/pages/presentations/presentations";
@import "pages/qotd";

// Import partials
@import "partials/heading";

// Import modals
@import "modals/market-selector";

body.public.page, body.public.osmosis, body.public.memorang {
  padding-top: 64px;
}
