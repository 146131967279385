.qotd-checklist {
  display: flex;
  position: relative;
  margin-left: -16px;
  margin-right: -16px;

  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  &--inner {
    align-items: center;
    display: flex;
    flex: 1;
  }
  &--triangle {
    position: absolute;
    right: 0;
    top: calc(50% - 16px);
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-right: 32px solid $grey;
  }
  .flex-item {
    line-height: 2;
  }
}

body.question-of-the-day {
  padding-top: 64px;

  .qotd-explanation2 {
    font-size: 18px;
    line-height: 26px;
  }

  .pencil-villain {
    max-width: 150px;
  }

  .qotd-ace-exams {
    padding: 0 20px;
    margin: 0 -10px;

    h2 {
      font-size: 18px;
      line-height: 24px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      color: $blue-steel;
      text-align: center;
    }
  }

  .sidebar-logo img {
    height: 20px;
  }

  .qotd-learn-more {
    font-size: 12px;
  }

  .we-get-it-img {
    max-width: 180px;
  }

  @media(min-width: 768px) {
    .intercept-headline {
      min-height: 42px;
    }
  }

  .qotd-shoutout-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 12px 24px;
    font-size: 20px;
    font-family: $raleway;
    font-weight: bold;
  }

  .qotd-shoutout-body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 24px;
    font-size: 17px;
    font-family: $raleway;
    font-weight: bold;

    .thumbnail-container {
      width: 234px;
      margin: auto;
    }

    p {
      font-size: 12px;
      color: $dry-cement;
      font-weight: normal;
    }

    &:before, &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .circle-number {
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    font-family: $opensans;
    background-color: $blue-steel;
    color: $dry-cement;
    margin: auto;
    font-weight: bold;
  }

  .card-icon  {
    display: block !important;
  }

}

@media(max-width: 768px) {
  .qotd-cta2 {
    width: 100%;
    padding: 10px 30px;
  }

  .qotd-checklist {
    text-align: center;
    &--container {
      display: block !important;
    }
    &--triangle {
      display: none;
    }
  }
}
