.has-ui-tooltip{
  position: relative;
}
.ui-tooltip{
  background: $blue;
  color: $white;
  border-radius: 4px;
  padding: 8px 10px;
  position: absolute;
  bottom: 100%;
  display: none;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  font-size: 16px;
  z-index: $zindex_navbar+1;
  min-width: 100px;

  &.ui-tooltip{
    &--visible{
      display: inline-table;
    }

    &--top{
      &-left{
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
      }
      &-right{
        transform: none;
        -webkit-transform: none;
      }
    }

    &--left{
      top: 50%;
      bottom: auto;
      right: 100%;
      left: auto;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }

    &--right{
      top: 50%;
      bottom: auto;
      left: 100%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }

    &--bottom{
      top: 100%;
      bottom: auto;
      &-left{
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
      }
      &-right{
        transform: none;
        -webkit-transform: none;
      }
    }
  }
}