.col--table {
  float: none;
  display: table-cell;
}
.col--no-spacing {
  padding: 0;
  margin: 0;
}

.img-fill{
  width: 100%;
}