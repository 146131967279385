@media (min-width: 768px) {
  .market-selector {
    .modal-dialog {
      width: 320px;
    }
  }
}

.market-close {
  cursor: pointer;
  line-height: 1.25em;
  text-align: center;
  width: 56px;
  height: 56px;
  color: #FFF;
  font-size: 12px;
  padding-top: 6px;
  position: absolute;
  right: 0px;
}

.try-it-now {
  border-radius: 4px;
}

.try-it-now-icon {
  font-size: 45px;
}

.try-it-now-text {
  font-size: 14px;
  line-height: 18px;
}