/* Learn */
/*====================================================NEW STUFF=====================================================
====================================================================================================================*/
body.learn, body.editor{
  background: $cloudier;
  width: 100%;

  .audio-controls {
    width: 100%;
  }

  .intercom-launcher-frame {
    visibility: hidden !important;
  }

  #intercom-container {
    display: none !important;
  }

  .intercom-launcher-badge-frame {
    visibility: hidden !important;
  }

  .md-select-menu-container {
    z-index: 901;
  }
}

.pre-learn-previous-next {
  position: absolute;
  right: 0;
}

.recommended-picmonics {
  .picmonic-thumbnail {
    border-radius: 4px;
  }

  .pls-list-item__title {
      width: calc(100% - 75px);
  }

  .pls-progress-bar--default {
    height: 4px;
  }

  .pls-progress-bar .progress {
    height: 4px;
  }

  .add-to-playlist-wrapper {
    display: none;
  }

  .ellipsis-wrapper {
    display: none;
  }

  .pls-list-item--picmonic {
    margin-left: 8px;
    margin-right: 8px;

    .pls-list-item__column-content {
      position: relative;
    }

    .pls-progress-bar {
      position:absolute;
      bottom: 8px;
      left: 8px;
      right: 8px;
    }

    &:hover {

      .ellipsis-wrapper {
        display: block;
        margin-top: 16px;
      }
    }
  }
}

.half-opacity {
  opacity: 0.5;
}

.my-facts {
  padding-left:8px;
}

.clear {
  clear: both;
}

.learn-wrapper{
  background: $charcoal-grey;
  padding-bottom:16px;

  .learn-header-wrapper{
    background: $platinum;
    color: $magnum;
    height: 40px;

    .pls-btn{
      padding: 6px 16px;
      min-height: 0px;
      margin-top: 4px;
    }

    .createdby {
      font-size: 16px;
      padding-top: 13px;
    }

    > ul{
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      -webkit-flex-flow: row wrap;
      justify-content: space-around;

      > li{
        flex-grow: inherit;
        float: left;
        margin-right: 8px;
        font-size: 12px;
        height: 40px;
        min-width: 48px;
        text-align: center;
        cursor: pointer;

        .circle-icon-container {
          border-radius: 50%;
          width: 33px;
          height: 33px;
          line-height: 21px;
          margin-top: 4px;
          text-align: center;
          padding-top: 5px;
          color: $wet-cement;

          &:hover {
            background: $dusk;
            color: $white;
          }
        }

        > i{
          display: block;
          margin: 0 auto;
        }

        &.filler{
          flex-grow: 1;
          margin: 0px;
          min-width: 0px;
        }

        .ellipsis-menu{
          margin-top: 4px;

          .ellipsis-wrapper{
            > .material-icons{
              color: $wet-cement;
            }
          }

          &:hover{
            .ellipsis-wrapper{
              > .material-icons{
                color: $white;
              }
            }
          }
        }

        &.modeTab{
          margin:0;
          height: 40px;
          line-height: 40px;
          padding: 0px 16px;
          font-weight: bold;
          font-size: 12px;

          &.selected{
            background: $charcoal-grey;
            color: $white;
          }
        }
      }

      button{
        background: $dusk;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        text-align: center;
        padding: 0px;
        margin: 4px;

        i{
          line-height: 24px;
        }

        &[disabled="disabled"]{
          opacity: 0.3;
        }
      }
    }
  }

  .learn-picmonic-wrapper{
    width: calc(100% - 320px);
    height: 100%;
    float:left;
    position: relative;
    z-index:0;

    .editor &{
      width: calc(100% - 368px);
    }
  }

  .learn & {
    &.workflow{
      .learn-picmonic-wrapper {
        width: calc(100% - 368px);
      }

      &.workflow-sidebar-open {
        .learn-picmonic-wrapper {
          width: calc(100% - 688px);
        }
      }

      &.facts-closed{
        .learn-picmonic-wrapper {
          width: calc(100% - 120px);
        }

        &.workflow-sidebar-open {
          .learn-picmonic-wrapper {
            width: calc(100% - 440px);
          }
        }
      }
    }
  }

  .editor & {
    &.workflow{
      .learn-picmonic-wrapper{
        width: calc(100% - 416px);
      }

      &.workflow-sidebar-open {
        .learn-picmonic-wrapper{
          width: calc(100% - 736px);
        }
      }
    }
  }

  &.editor{
    .user-editing-overlay{
      background: rgba($black, 0.7);
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 300;
      padding-top: 5%;
      color: $white;
    }

    .users-editing-wrapper {
      .users-editing-picture {
        margin: 5px;
        width: 38px;
        height: 38px;
        float: left;
        position: relative;
      }
    }
  }

  .prelearn-overlay{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: $white;
    border-radius: 5px;
  }

  .learn-subtitles{
    display: none;
  }

  &.subtitles-open{
    .learn-image-wrapper{
      height: calc(100% - 100px) !important;
    }

    .learn-subtitles{
      height: 100px;
      width: 100%;
      background: $magnum;
      position: relative;
      overflow: hidden;
      display: block;

      &__slider{
        color: $white;
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        width: 60%;
        margin:0px auto;
        padding-top: 30px;
        transition-delay: 400ms;
      }
    }
  }
}

.learn-info{
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  .breadcrumbs {
    line-height: 16px !important;
    padding-bottom: 4px;

    .material-icons {
      font-size: 14px;
      line-height: 16px;
      vertical-align: text-top;
    }
  }
}

@media(min-width: 767px){
.learn{
  &--theater{
    height: 100vh;
    width: calc(100vw - #{$navbar_width});
    overflow: hidden;

    .learn-info {
      display: none;
    }

    .player-container{
      height: calc(100vh - 40px);
      margin: 0px;

      .player-sidebar-wrapper{
        border-radius: 0px;
      }

      .learn-body-wrapper{
        box-shadow: none;
        border-radius: 0px;
        border-top: 4px solid $charcoal-grey;
      }
    }

    .prelearn-overlay{
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
}

.editor-modal {
  .list-group-item {
    line-height: 32px;
  }
}

body.learn:-webkit-full-screen{
  padding-top:0;

  .navbar-wrapper{
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh - 40px);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
      border-top: 4px solid $charcoal-grey;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .prelearn-overlay{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .learn-info{
    display: none;
  }
}

.prelearn-overlay-mobile-lock {
    @media(max-width: 480px){
      overflow: hidden;
      position: fixed;
      height: 100%;
    }
}

body.learn:fullscreen{
  padding-top:0;

  .navbar{
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh - 40px);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
      border-top: 4px solid $charcoal-grey;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .prelearn-overlay{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .learn-info{
    display: none;
  }
}

body.learn:-moz-full-screen{
  padding-top:0;

  .navbar{
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh - 40px);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
      border-top: 4px solid $charcoal-grey;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .prelearn-overlay{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .learn-info{
    display: none;
  }
}

body.quiz:-moz-full-screen {
  padding-top:0;

  .quiz-answer-container {
    display: none;
  }

  .learn-image-wrapper {
    border-radius: 0 !important;
  }

  .quiz-topic-card {
    padding: 0 !important;
    margin: 0 !important;
  }

  .quiz-picmonic-container {
    width: 100%;
  }

  .quiz-topic-heading {
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .learn-info{
    display: none;
  }
}

body.quiz:fullscreen {
  padding-top:0;

  .quiz-answer-container {
    display: none;
  }

  .learn-image-wrapper {
    border-radius: 0 !important;
  }

  .quiz-topic-card {
    padding: 0 !important;
    margin: 0 !important;
  }

  .quiz-picmonic-container {
    width: 100%;
  }

  .quiz-topic-heading {
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .learn-info{
    display: none;
  }
}

body.quiz:-ms-fullscreen {
  padding-top:0;

  .quiz-answer-container {
    display: none;
  }

  .learn-image-wrapper {
    border-radius: 0 !important;
  }

  .quiz-topic-card {
    padding: 0 !important;
    margin: 0 !important;
  }

  .quiz-picmonic-container {
    width: 100%;
  }

  .quiz-topic-heading {
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .learn-info{
    display: none;
  }
}

body.quiz:-webkit-full-screen {
  padding-top:0;
  
  .quiz-answer-container {
    display: none;
  }

  .learn-image-wrapper {
    border-radius: 0 !important;
  }

  .quiz-topic-card {
    padding: 0 !important;
    margin: 0 !important;
  }

  .quiz-picmonic-container {
    width: 100%;
  }

  .quiz-topic-heading {
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .learn-info{
    display: none;
  }
}

body.learn:-ms-fullscreen{
  padding-top:0;

  .navbar{
    display: none;
  }

  .learn-wrapper{
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .main-content{
    top:0 !important;
    height: 100vh;
    width: 100vw;
    margin: 0px;
  }

  .player-container{
    height: calc(100vh - 40px);
    margin: 0px;

    .player-sidebar-wrapper{
      border-radius: 0px;
    }

    .learn-body-wrapper{
      box-shadow: none;
      border-radius: 0px;
      border-top: 4px solid $charcoal-grey;
    }

    .player-sidebar-wrapper{
      border-radius: 0px !important;
    }
  }

  .prelearn-overlay{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .learn-info{
    display: none;
  }
}

body.editor{
  overflow: hidden;

  .learn-body-wrapper{
    height: calc(100vh - 40px);
  }

  .learn-header-wrapper{
    position: relative;
    z-index: 2;
  }

  .player-sidebar-wrapper{
    background: $white;
  }

  .learn-fact-wrapper{
    background: $white;

    .fact-number-wrapper{
      background: $white;
    }
  }
}

.learn-top-buttons .icon-expand, .learn-top-buttons .icon-compress{
  border: 1px solid #666;
  padding: 0 4px;
  border-radius: 3px;
}

.learn-top-buttons.fullscreen .icon-expand, .learn-top-buttons.fullscreen .icon-compress, .learn-top-buttons .icon-expand:hover, .learn-top-buttons .icon-compress:hover{
  border: 1px solid #00A0DF;
  color: #00A0DF;
}

.audio-speed .dropdown-toggle {
  font-weight: bold;
}

#learn-audio-speed-select button {
  width: 100%;
  margin-top: -2px;
}

.learn-header-wrapper .tools-wrapper{
  width: calc(100% - 450px);
  height: 40px;
  line-height: 40px;
  color: $jullian-gray !important;
}

.editor .learn-header-wrapper .tools-wrapper {
  width: calc(100% - 180px);
}

#learn-share-dropdown-link {
  cursor: pointer;
}

@media(max-width: 1245px) {
  .tools-wrapper span.hidden-sm {
    display: none !important;
  }
}

.tools-wrapper .dropdown-menu {
  padding-bottom: 0;
  left: 0;
  li {
    border-bottom: 1px solid $light-grey;
  }
}

.tools-wrapper .vert-divider{
  margin: 0 15px;
  border-right:1px solid #CECECE  ;
}

.learn-educate-tooltip-wrapper {
  width: 50px;
  position: relative;
  text-align: center;
  border-left: 1px solid #333;
}
.learn-educate-tooltip {
  width: 300px;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 4px rgba(0,0,0,0.4) !important;
  color: #00A0DF;
  z-index: 100;
  line-height: 1.33em;
  border-radius: 4px;
  bottom: 8px;
  right: 8px;
  padding: 4px;
  top: auto !important;
}
.learn-educate-tooltip p {
  padding-top: 12px;
}

.player-container{
  height: calc(90vh - 48px - 26px);
  margin: 16px;
  margin-bottom: 0;
  box-shadow: 0 0 10px #000;
  position: relative;
  transition: height 0.4s, padding 0.4s;
  z-index: 0;
  border-radius: 5px;

  .learn-body-wrapper{
    height: calc(100% - 68px);
    width: 100%;
    overflow: hidden;
    position:relative;
    border-top-right-radius: 5px;
    transition: height 1s;

    &.show-post-bar {
      height: calc(100% - 120px);
    }
  }

}

.learn-footer-wrapper .title{
  line-height: 16px;
  margin-top: 14px;
}

.right-sidebar-open .learn-picmonic-wrapper{
  width: calc(100% - 375px) !important;
  .editor & {
    width: calc(100% - 743px) !important;
  }
}

.editor {
  &-right-sidebar-wrapper {
    width: 375px;
    float: left;
    height: 100%;
    box-shadow: 0 0 4px rgba(0,0,0,.75);
    z-index: 2;
    .content {
      padding: 16px;
    }
  }
}

.right-sidebar-open .learn-summary-wrapper{
  width: calc(100% - 330px);
}

.learn-picmonic-wrapper.half-size {
  width: 50% !important;
}

.learn-picmonic-wrapper.facts-open-half-size {
  width: calc(50% - 320px) !important;
}

.learn-picmonic-wrapper.facts-closed-half-size {
  width: calc(50% - 45px);
}

.learn-picmonic-wrapper.facts-open-quarter-size {
  width: calc(100% - 650px) !important;
}

.learn-image-wrapper{
  height: 100%;
  width:100%;
  position:relative;
  overflow:hidden;
  background: #999;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
.learn-loading-assets .logo-loading{
  width: 194px;
  height: 171px;
  margin: 0 auto;
  position: relative;
}

.learn-loading-assets .logo-loading .logo-loading-incomplete{
  z-index:1;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  height: 100%;
  overflow: hidden;
}
.learn-loading-assets .logo-loading .logo-loading-complete{
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media(max-width: 768px) {
  .learn-loading-assets {
    position: absolute;
    width: 100%;
    height: 171px;
    top: calc(50vh - 85px);
    left: 0;
  }
}

.learn-background{
  width: 100%;
  height: 100%;
  position:relative;
  z-index:0;
}
.learn-background .background{
  width: 100%;
  height: 100%;
  z-index:0;
  position:absolute;
  top: 0;
  left:0;
}

.comment-notes-selector {
  margin-bottom: 10px;
  width: calc(100% - 44px);
  float: left;
}

.comment-notes-close {
  text-align: center;
  width: 36px;
  height: 36px;
  float: right;
  color: $blue;
  font-size: 12px;

  i {
    font-size: 18px;
  }

  span {
    position: relative;
    top: -4px;
  }
}

.learn-right-sidebar-wrapper{
  background:$white;
  padding: 10px;
  height:100%;
  position: absolute;
  width: 330px;
  z-index:1;
  right: -330px;
  top:0px;

  &.expanded {
    width: 50%;
    right: -50%;
  }

  .right-sidebar-open &{
    right:0;
    box-shadow: 0 0 4px rgba(0,0,0,0.5);
  }
}

.transition .learn-facts-wrapper {
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.left-sidebar-wrapper{
  float:left;
  height:100%;
  position: relative;
  width: 320px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  z-index:1;

  .editor-tab-wrapper{
    display:none;
  }

  &.show-post-learn {
    @media(max-width: 767px) {
      z-index: 0;
    }
  }

  .editor &{
    width:368px;

    .learn-fact-wrapper{
      cursor:default;

      &.editable{
        cursor:pointer;
      }
    }

    .editor-tab-wrapper{
      display:block;
      float: left;
      height:100%;
      width:48px;
      background-color: $blue-steel;
      z-index:4;
      position:relative;

      .editor-tab{
        text-align:center;
        cursor:pointer;

        &.selected{
          background-color:$white;

          i{
            color:$blue;
          }
        }

        i{
          font-size:22px;
          line-height:48px;
          color:$dry-cement;
        }
      }
    }
  }
}

@media(min-width: 768px) {
  .transition .left-sidebar-wrapper {
    transition: all 0.6s;
  }

  .transition .learn-summary-wrapper {
    transition: all 0.6s;
  }

  .transition .player-sidebar-wrapper {
    transition: left 0.6s;
  }
}

.learn-summary-wrapper {
  overflow-y: scroll;
  float: left;
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  padding: 20px;
  z-index:1;
  background-color:#fff;
}

.learn-summary-wrapper.half-size {
  width: 50%;
}

.learn-summary-wrapper ul.markets {
  list-style-type: none;
  display: block;
  margin-top: 0px;
  margin-bottom: 15px;
}

.learn-summary-wrapper ul.markets li {
  display: block;
  float: left;
  margin-right: 10px;
  padding: 3px 4px;
  font-size: 0.9em;
  background-color: #CCCCCC;
  color: $jullian-gray;
  font-weight: 600;
  margin-top: 5px;
}

.learn-back-button-div {
  height:40px;
  font-size:14px;
  margin-top:14px !important;
  cursor: pointer;
  border-left: none;
}

.learn-back-icon {
  font-size:18px;
  position:relative;
  top:3px;
}

.learn-summary-wrapper ul.markets li.primary {
  background-color: #BFE1F6 !important;
}

.learn-summary-wrapper .breadcrum i {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  font-size: 16px;
  margin-right: 4px;
}

.summary-icon {
  font-size: 18px;
  cursor: pointer;
}


.learn-sidebar-toggle{
  width: 15px;
  height: 40px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: $cloudy;
  position:absolute;
  line-height:40px;
  text-align: center;
  right: -15px;
  bottom: 75px;
  cursor:pointer;
  box-shadow: 1px 0 2px rgba(0,0,0,0.5);
  z-index: 3;

  .learn-right-sidebar-wrapper & {
    right: unset;
    left: -15px;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    box-shadow: -1px 0 2px rgba(0,0,0,0.5);
    display: none;
  }
}

.right-sidebar-open .learn-right-sidebar-wrapper .learn-sidebar-toggle{
  display: block;
}


.learn-controls{
  height:60px;
  background: #3F3F3F; /* Old browsers */
  color: $white;
  line-height: 60px;
  font-weight: bold;
  font-size: 12px;
  display: flex;

  .learn-control{
    margin-left: 16px;
    position: relative;

    > i{
      line-height: 65px;
    }
  }
}
.audio-mode-label{
  width: 70px;
}
.audio-mode{
  border-radius: 4px;
  background: #1c1c1c;
  border: 2px solid #1c1c1c;
  height: 33px;
  line-height: 0;
  width: 175px;
  margin-top: 14px;
  cursor: pointer;

  .btn{
    font-size: 12px;
    height: 29px;
    border-radius: 2px;
  }
}

.mobile .audio-mode{
  width: 184px
}

.audio-mode .btn{
  background: none;
  color: #FFF;
}

.audio-controls-wrapper{
  width: 95px;
  margin-right: 15px;
}

.player-container, .quiz-picmonic-container {
  .player-container {
    margin: 0;
    height: calc(100vh - 100px);
  }

  .audio-play{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    background: $blue;
    margin: 10px auto;
    cursor: pointer;
    color: #000;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: 0 4px;
  }
  .audio-play i{
    font-size: 30px;
    line-height: 40px;
  }
}

.miniplayer-wrapper {
  
  .audio-play{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 44px;
    background: #1c1c1c;
    margin: 10px auto;
    cursor: pointer;
    color: #00A0DF;
  }
  .audio-play i{
    font-size: 16px;
  }
}

.audio-play .icon-play{
  margin-left:3px;
}

.audio-play .icon-pause{
  margin-left:-1px;
}

.audio-speed{
  margin-left: -5px;
  background: #1c1c1c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: $white;
  height: 24px;
  line-height: 24px;
  margin-top: 18px;
  width: 43px;
  cursor: pointer;
}

.audio-speed button{
  color:$white;
}

.audio-speed .dropdown-menu{
  background: #1c1c1c;
  border: none !important;
  outline: none !important;
  min-width: unset;
  box-shadow: 0 -2px 5px #000;
  padding-left: 5px;
}

.audio-speed .dropdown-menu li{
  width: 37px;
  padding-right:5px;
  cursor:pointer;
}

.audio-seek{
  height:16px;
  background: #1C1C1C;
  border-radius: 6px;
  border:2px solid #1C1C1C;
  overflow:hidden;
  margin-top: 22px;
  cursor:pointer;
  width: 100%;
}
.audio-seek .audio-seek-bar{
  background: #00A0DF;
  width: 0px;
  height:12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

}

.postlearn-bar {
  height: 0px;
  background-color: $deep-sea;
  padding: 8px;
  text-align: center;
  color: $white;
  transition: height 1s, margin 1s;
  width: 100%;
  position: absolute;
  z-index: -1;

  &.open {
    height: 52px;
  }
}

.postlearn-overlay{

  .up-next {
    margin-top: 30px;
  }

  .icon-repeat {
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
  }

  position:absolute;
  text-align: center;
  background-color: $blue-steel;
  opacity: 0.96;
  z-index:200;
  width: 100%;
  height: 100%;
  top: 0;

  .btn {
    font-family: Raleway;
    font-size: 17px;
    line-height: 1.3;
    border-radius: 4px;
    font-weight: lighter;
    text-shadow: none;
  }

  

  * {
    opacity: 1;
  }

  .review-icon {
    opacity: 1 !important;
    width: 16px;
    vertical-align: text-top;
  }

  .post-learn-review {
    @media(max-width: 767px) {
      margin-top: 16px;
    }
  }

  .post-learn-close {
      cursor: pointer;
      line-height: 1.25em;
      text-align: center;
      width: 56px;
      height: 56px;
      color: #FFF;
      font-size: 12px;
      padding-top: 6px;
      position: absolute;
      right: 0px;
  }

  .watch-again {
    position: absolute;
    bottom: 40px;
    width: 100%;
    font-family: "Open Sans";
    font-size: 16px;

    @media(max-width: 767px) and (min-height: 568px) {
      bottom: 80px;
    }

    @media(max-height: 700px) and (max-width: 767px) {
      display: none;
    }

    .divider {
      max-width: 700px;
      margin: auto;
    }

    img {
      opacity: 0.7;
    }
  }

  h4 {
    font-family: "Open Sans";
    font-size: 16px;
  }

  h3 {
    font-family: "Open Sans";
    font-size: 24px;
  }

  .previous-next-div {
    width: 80%;
    margin:auto;

    i {
      font-size: 36px;
      margin-top: 64px;
    }
  }

  .progress-bar-wrapper {
    background: #616b80;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    width: 70%;
    margin:auto;
  }

  .progress-bar-wrapper .progress-bar {
    background: #18f291;
    height: 8px;
    border-radius: 4px;
    transition: width 1.5s;
  }
}

.hotbox-wrapper, .character-overlay{
  position:absolute;
  background: rgba(0,0,0,0.8);
  z-index:2;
  width: 100%;
  height: 100%;
  top: 0;
}
.character-overlay{
  z-index: 300;

  .editor &{
    background: none;
  }

}
.hotbox{
  position:absolute;
}
.hotbox canvas{
  width: 100%;
  height: 100%;
}

.learn-hotbox-blur{
  width: 100%;
  height: 100%;
}

.learn-blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.audio-volume-wrapper{
  width: 105px;
}

.audio-volume{
  height: 60px;
  width: 105px;
}

.audio-volume .icon-volume-up, .audio-volume .icon-volume-off{
  margin-top: 21px;
  font-size: 17px;
  width: 15px;
  float: left;
  position: relative;
}

.audio-volume .icon-volume-off:after{
  content: '\f00d';
  position: absolute;
  font-size: 10px;
  top: 3px;
  left: 10px;
}

.audio-volume i {
  cursor: pointer;
}

.audio-volume-wrapper .slider-wrapper{
  float: left;
  width: calc(100% - 30px);
  margin-top: 25px;
}

.audio-time{
  width: 75px;
}

.learn-top-buttons {
  color: $white;
  cursor: pointer;
  position: relative;
  font-size: 12px;
}

.learn-top-buttons:hover {
  color: #6EC7E7;
}

.modal-body .dropdown {
  position: relative;
  top: 8px;
}

.learn-top-buttons:active, .learn-top-buttons.active {
  color: #40A2DC;
}

.learn-top-buttons .icon-play{
  transform: rotate(270deg) scaleX(0.6);
  -webkit-transform: rotate(270deg) scaleX(0.6);
  position: absolute;
  bottom: -15px;
  left: calc(50% - 6px);
  display:none;
}
.learn-top-buttons.active .icon-play{
  display:block;
}

.btn-flat-blue h3{
  color:$white;
}

.learn-fact-answer-wrapper{
  padding: 10px;
}

.learn-fact-wrapper{
  background: $cloudier;

  .learn-fact-answer-wrapper{
    border-bottom:3px solid #EEE;
    padding: 0px;
  }

  .icon-edit-pencil{
    display:none;
  }

  &.header {
    .fact-header-wrapper{
      border: none;

      .fact-title {
        padding-top: 6px;
      }
    }
  }

  &.community-group {
    border-bottom: solid 1px $dry-cement;

    .icon-thumbs-down {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right:42px;
      display: none;

      &:hover {
        color: #91939A !important;
      }
    }
    .icon-plus-circle {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right:12px;
    }

    .icon-chevron-down, .icon-chevron-up {
      font-size: 14px;
      position: absolute;
      top: 10px;
      right:12px;
    }

    .fact-title-wrapper {
      padding-left:8px;
      padding-right:56px;
    }

    &:hover {
      .icon-thumbs-down {
        display: block;
      }
    }

  }

  &.community-fact {
    .icon-thumbs-down {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right: 34px;
      display: none;

      &:hover {
        color: #91939A !important;
      }
    }
    .icon-plus-circle {
      font-size: 20px;
      position: absolute;
      top: 10px;
      right: 4px;
      display: none;

      &:hover {
        color: #91939A !important;
      }
    }

    .icon-chevron-down, .icon-chevron-up {
      font-size: 14px;
      position: absolute;
      top: 10px;
      right:12px;
      display: none;
    }

    .fact-title-wrapper {
      padding-left:8px;
      padding-right:62px;
    }
    .fact-header-wrapper {
      padding-left: 12px;
    }

    &:hover, &.selected {
      .icon-thumbs-down, .icon-plus-circle, .icon-chevron-down, .icon-chevron-up {
        display: block;
      }
    }
  }

}

.learn-fact-tooltip .learn-fact-answer-wrapper .learn-fact-answer{
  padding-left: 16px;
}

.learn-fact-tooltip .learn-fact-answer-wrapper .learn-fact-answer label {
  text-indent: -16px;
}

.learn-fact-wrapper .learn-fact-answer-wrapper .learn-fact-answer{
  border-bottom:1px solid #EEE;
  background: $white;
  padding: 5px 10px;
}

.learn-fact-wrapper .learn-fact-answer-wrapper .learn-fact-answer:hover{
  background:#EEE;
}

.learn-next-fact .btn{
  min-width: 95px;
}

.circle-step-wrapper{
  height: 120px;
  width: 90px;
  margin:0px auto;
  position:relative;
}

.circle-step-small-back{
  z-index: 0;
  position: absolute;
  border: 1px solid #cecece;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: $white;
  display: none;
  top: -6px;
  left: 0px;
}

.circle-step-large-back{
  z-index: 1;
  position: relative;
  border: 1px solid #cecece;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: $white;
  font-size: 71px;
  line-height: 90px;
}

.circle-step-small{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid $white;
  z-index: 3;
  background: $orange;
  margin-top: -6px;
  margin-left: 0;
  color: $white !important;
  position: absolute;
  line-height: 29px;
  font-size: 14px;
  display:none;
}

.circle-step-wrapper.complete .circle-step-large-back{
  color: $orange;
}

.circle-step-wrapper.complete .circle-step-small, .circle-step-wrapper.complete .circle-step-small-back {
  display:block;
}

#kaplan {
  margin-top: calc((100vh - 221px) / 2 - 60px);
}

#kaplan .modal-title {
  text-align: center;
  color: $white;
}

#kaplan .modal-footer a {
  width: 100%;
}

#kaplan .modal-footer a {
  width: 100%;
}

#kaplan p {
  font-size: 14px;
  line-height: 20px;
}

.osmosis-icon {
  margin-left: 5px;
  font-style: italic;
  font-weight: normal;
  border: 1px solid;
  border-radius: 20px;
  width: 22px;
  padding-left: 5px;
  line-height: 20px;
  vertical-align: middle;
}

#osmosis .modal-title {
  text-align: center;
  color: $white;
}

#osmosis .modal-footer a {
  width: 100%;
}

img.osmosis-logo {
  max-width: 300px;
}

#osmosis p {
  font-size: 14px;
  line-height: 20px;
}

.mobile .learn #footer .left-panel .center-content {
  left: 490px;
}

.close {
  position: relative;
  z-index: 1000;
}

.youtube-modal .close {
  background-color: #000000;
  border: 3px solid #000000;
  border-radius: 17px;
  display: block;
  height: 33px;
  opacity: 1;
  position: absolute;
  right: -13px;
  text-align: center;
  top: -13px;
  width: 34px;
  z-index: 1045;
}

.pointer{
  cursor:pointer;
}
.redo-button i{
  font-size:30px;
}

.pulse-red, .pulse-red * {
  -webkit-animation: pulse_red 1s infinite alternate;
  -moz-animation: pulse_red 1s infinite alternate;
  -ms-animation: pulse_red 1s infinite alternate;
  -o-animation: pulse_red 1s infinite alternate;
  animation: pulse_red 1s infinite alternate;
}

@-webkit-keyframes pulse_red {
  from { color: #cecece; }
  to { color: #e9511c; }
}
@-moz-keyframes pulse_red {
  from { color: #cecece; }
  to { color: #e9511c; }
}
@-ms-keyframes pulse_red {
  from { color: #cecece; }
  to { color: #e9511c; }
}
@-o-keyframes pulse_red {
  from { color: #cecece; }
  to { color: #e9511c; }
}
@keyframes pulse_red {
  from { color: #cecece; }
  to { color: #e9511c; }
}

.pulse-bg-blue, .pulse-bg-blue * {
  -webkit-animation: pulse_blue 1s infinite alternate;
  -moz-animation: pulse_blue 1s infinite alternate;
  -ms-animation: pulse_blue 1s infinite alternate;
  -o-animation: pulse_blue 1s infinite alternate;
  animation: pulse_blue 1s infinite alternate;
}

@-webkit-keyframes pulse_blue {
  from { background-color: #cecece; }
  to { background-color: #00a0df; }
}
@-moz-keyframes pulse_blue {
  from { background-color: #cecece; }
  to { background-color: #00a0df; }
}
@-ms-keyframes pulse_blue {
  from { background-color: #cecece; }
  to { background-color: #00a0df; }
}
@-o-keyframes pulse_blue {
  from { background-color: #cecece; }
  to { background-color: #00a0df; }
}
@keyframes pulse_blue {
  from { background-color: #cecece; }
  to { background-color: #00a0df; }
}

.label-margin-bottom {margin-bottom: 7px;}

.info-button {
  font-size: 20px;
  cursor: pointer;
  width: 100px;
  text-align: right;
}

.close-info-summary {
  position: absolute;
  top: 0;
  right: 14px;
  padding-top: 10px;
  font-size: 1.5em;
  z-index: 1000;
}
.close-info-summary:hover {cursor: pointer;}
.display-inline {display: inline !important;}
.osmosis-img {
  max-width: 500px;
  margin: auto;
}
.font-12 {font-size: 12px;}
.quiz-answer-buttons-small {
  width: 60px !important;
  font-size: 18px;
}


#LearnComments {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 112px);
}

.picmonic-review .nav-tabs{
  position:relative;
}
.picmonic-review .nav-tabs.positive{
  border-bottom:2px solid #74ad30 !important;
}
.picmonic-review .nav-tabs.negative{
  border-bottom:2px solid #e9511c !important;
}
.picmonic-review .nav-tabs li{
  margin-bottom: 0px !important;
  border-bottom: 1px solid $white;
}
.picmonic-review .nav-tabs.positive li.active{
  border-bottom: 1px solid #74ad30;
}
.picmonic-review .nav-tabs.negative li.active{
  border-bottom: 1px solid #e9511c;
}
.picmonic-review .nav-tabs li a.positive{
  background:#9dc56e !important;
}
.picmonic-review .nav-tabs li a.negative{
  background:#f08560 !important;
}
.picmonic-review .nav-tabs li a{
  border: none !important;
  color: $white !important;
}
.picmonic-review .nav-tabs li.active a.positive{
  background: #74ad30 !important;
}
.picmonic-review .nav-tabs li.active a.negative{
  background: #e9511c !important;
}

.font-14 {font-size: 14px;}
.font-15 {font-size: 15px;}
.learn-reference-li {line-height: 2em;}

.definition-visibility-toggle {
  cursor: pointer;
}

.learn-share-notes-btn {
  text-align: right;
  float: right;
  padding-top: 7px;
  clear: both;
  cursor: pointer;
}

.shared-green {color: lawngreen;}
.share-save-notes {margin-top: 55px;}
.add-bottom-publish-note {margin-bottom: 7px;}

.action-button i.icon-circle-o-notch{
  font-size: 34pt;
  margin:20px 0 15px 0;
}

.btn-upsell {
  font-size: 24px;
  color: #333;
  border-radius: 8px;
}

.btn-upsell:hover {
  color: #333;
}

.action-button i{
  font-size: 68pt;
}

.action-button i.icon-circle-o-notch{
  font-size: 34pt;
  margin:20px 0 15px 0;
}

.action-button span{
  font-size:13pt;
}


.learn-master-complete{
  font-size: 16px;
}

.time-remaining{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.btn-flat-red-inverse .time-remaining{
  border: 1px solid #e9511c;
}
.btn-flat-green-inverse .time-remaining {
  border: 1px solid #67c25e;
}

.comment-feed-wrapper{
  height: calc(100% - 115px);
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 2px solid #CCC;
  padding: 5px;
}

.fact-classifier-select {
  font-size: 12px;
  margin: 8px 0 3px -3px;
  float: left;
  width: 90%;
}

.fact-classifier-about {
  float: right;
  margin-top: 9px;
  margin-right: 5px;
}

.fact-classifier-loading {
  float: right;
  margin-top: 30px;
  margin-right: -11px;
}

body.learn .related-picmonics-modal .icon-close {
  position: absolute;
  top: -18px;
  right: 0px;
}

body.learn .uservoice-feedback {
  cursor: pointer;
}

.add-fact-btn {
  width:95%;
  margin: auto;
  font-size: 16px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-fact-btn i {
  margin-top: -3px;
}

.editor-add-btns {
  div {
    padding-right:4px;
  }
  div+div{
    padding-left:4px;
    padding-right:15px;
  }
  .btn{
    border-radius: 4px;
    font-family:Raleway;
    font-size:16px;
    width:100%;
  }
}


.fact-character-group {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;

  .fact-character{
    .asset-mask{
      overflow: hidden;
    }
  }

  &.characters-selected.learn-hotspot {
    opacity: 0.5;
  }

  &.selected {

    opacity: 1;

    .learn &{
      z-index: 301;
    }

    .miniplayer &{
      z-index: 301;
    }
  }

  .editor & {

    &.editable{
      .fact-character{
        pointer-events: all;

        .character-tools-wrapper{
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: move;
        }

        .asset-wrapper{
          background: rgba($magnum, 0.3);
          cursor: pointer;

          &:hover {
            background: rgba($magnum, 0.6);
          }
        }


        &.selected{
          .asset-wrapper{
            background: rgba($magnum, 0.8);
            cursor: move !important;
            border: 1px dashed $white;
          }

          .character-tools-wrapper{
            z-index: 101;
            border: 1px dashed $white;
          }

          .resize-anchor, .fact-editor-tools-wrapper {
            display: block;
          }
        }

        &.cropping{
          .asset-mask{
            overflow: visible;
          }

          .resize-anchor{
            border-radius: 0px !important;
            border: none !important;
            background: none;
            box-shadow: none;

            &.top{
              border-top: 3px solid $white !important;
              top: 0;
            }
            &.bottom{
              border-bottom: 3px solid $white !important;
              bottom: 0;
            }
            &.left{
              border-left: 3px solid $white !important;
              left: 0;
            }
            &.right{
              border-right: 3px solid $white !important;
              right: 0;
            }
          }
        }
      }
    }
  }
}

.fact-character{
  position: absolute;
  pointer-events: none;
  &.blink {
    opacity: 1;
  }
  &.does-blink {
    opacity: 0.2;
    max-width: 100%;
    max-height: 100%;
  }
  .learn &, .miniplayer & {
    transition: width 1s, height 1s, top 1s, left 1s;
  }

  .asset-mask{
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .asset-wrapper{
    position: absolute;

    &.mirrored_x, & .mirrored_x {
      transform: rotateX(180deg);
    }

    &.mirrored_y, & .mirrored_y {
      transform: rotateY(180deg);

      &.mirrored_x, & .mirrored_x {
        transform: rotateX(180deg) rotateY(180deg);
      }
    }
  }

  .fact-editor-tools-wrapper{
    height: 50px;
    position: absolute;
    left: auto;
    right: auto;
    top: -50px;
    display: none;
    cursor: default;
    min-width: 205px;
    pointer-events:none;

    .fact-editor-tools{
      height: 40px;
      background: $magnum;
      border-radius: 4px;
      border: 1px solid $white;
      pointer-events: all;
      display: inline-block;

      button{
        height: 40px;
        min-width: 40px;
        text-align: center;
        line-height: 40px;
        color: $cloudy;
        font-size: 16px;

        &:hover{
          color: $white;
        }
      }

      &--crop {
        border: none;

        button {
          color: $magnum;
        }

        button:hover{
          color: $magnum;
        }
      }
    }
  }


  .resize-anchor{
    width: 16px;
    height: 16px;
    background: $magnum;
    box-shadow: 0 0 8px 0 $black;
    border: solid 2px $white;
    position: absolute;
    border-radius: 50%;
    z-index: 105;
    display: none;

    &:hover{
      background: $black;
    }

    &.top{
      top: -5px;

      &.left{
        left: -5px;
        cursor: nwse-resize;
      }

      &.right{
        right: -5px;
        cursor: nesw-resize;
      }
    }

    &.bottom{
      bottom: -5px;

      &.left{
        left: -5px;
        cursor: nesw-resize;
      }

      &.right{
        right: -5px;
        cursor: nwse-resize;
      }
    }
  }

  img{
    pointer-events: none;
    position: absolute;
  }

  .learn &{
    pointer-events: none;

    .asset-wrapper{
      pointer-events: none;
    }
  }
}

.learn-copyright {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #000000;
  z-index: 30;
  text-shadow: 1px 1px rgba(255,255,255,.5);
}

@media all and (max-width: 1300px) {
  .learn-feedback .btn-error {
    display: none !important;
  }
}

.learn-feedback .btn-error-inverse {
  border: none;
}

.learn-feedback .btn-error {
  padding-top: 4px;
  padding-bottom: 4px;
}

@media (min-width: 480px) {
  .facts-closed .learn-fact-wrapper.selected .fact-summary{
    margin-left: -320px;
  }
  .facts-closed .fact-title-wrapper {
    margin-left: -320px;
  }

  .facts-closed .fact-title-wrapper .fact-picmonic {
    display: none;
  }

  .facts-closed .fact-title-wrapper .fact-classifier {
    display: none;
  }

  .facts-closed .fact-header-wrapper {
    height: 56px;
    background:none !important;
    overflow-y: hidden;
    border-bottom: none;
  }
  .facts-closed .fact-number-wrapper {
    width: 72px !important;
    border-bottom: 1px solid #EEE;
  }
  .facts-closed .learn-fact-wrapper {
    height: 56px;
  }
  .facts-closed .left-sidebar-wrapper {
    width: 72px;
  }
  .facts-closed .player-sidebar-wrapper{
    width: 72px;
  }
  .facts-closed .learn-fact-wrapper:hover .fact-header-wrapper, .facts-closed .learn-fact-wrapper:hover {
    background: none !important;
  }
  .facts-closed .header {
    left: -320px !important;
    display: none;
  }
  .facts-closed .learn-picmonic-wrapper {
    width: calc(100% - 72px);
  }
}

@media(max-width: 767px) {
  .learn-header-wrapper .tools-wrapper{
    width: 50px;
  }

  .btn-upsell {
    font-size: 5vw;
  }

  .upsell-header {
    font-size: 5vw;
  }
}

@media (min-width: 768px) {
  .learn-fact-wrapper .learn-fact-tooltip-footer{
    display:none;
  }
  .quiz .hotbox-wrapper{
    background: none;
  }
  .transition .learn-right-sidebar-wrapper{
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
  }
  .transition .learn-picmonic-wrapper {
    transition: all 0.6s;
    -webkit-transition: all 0.6s;
  }
}

@media screen and (orientation:landscape) and (max-height: 480px) {
  .postlearn-overlay {
    height: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .learn-wrapper .learn-picmonic-wrapper{
    z-index: 2;
  }

  .left-sidebar-wrapper {
    z-index: 3;
  }
}

@media(max-width: 480px) {
  .player-container {
    position: relative;
    z-index: 1;
  }

  .learn-info {
    position: relative;
    z-index:0;
    -webkit-overflow-scrolling: touch;

    &-scrollable.learn-info--learn{
      // @media(max-width: 480px) {
        height: auto !important;
        overflow: auto !important;
        padding-bottom: 56px;
      }
    // }
  }
  .learn-right-sidebar-wrapper {
    position: fixed;
    bottom: 57px;
    top: 40px;
    height: auto;
  }
  .player-stettings-wrapper {
    position: static;
  }
  .player-settings-tooltip-wrapper {
    top: 0px !important;
    bottom: 0px;
    overflow-y: auto;
  }
  .prelearn-overlay:not(.guest) {
    position: fixed;
  }
  .postlearn-overlay {
    bottom: 56px;
    height: auto;
    position: fixed;
  }
  .watch-again {
    display:none;
  }
  .froala-wrapper.f-basic {
    height: 90% !important;
  }
  .learn-fact-tooltip-wrapper{
    display:none;
  }
  .fact-summary{
    width: 100% !important;
  }

  .learn-fact-wrapper.header .fact-title-wrapper {
    width: 100% !important;
  }

  .fact-number-wrapper{
    width:65px !important;
  }
  .learn-fact-wrapper{
    width: 100% !important;
  }
  .left-sidebar-wrapper{
    display: none;
  }
  .learn-facts-wrapper {
    width: 100%;
  }

  .player-container{
    padding: 0px;
    margin: 0px;
    height: 360px !important;
  }

  .learn-info{
    height: calc(55vh - 40px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .learn-wrapper .learn-picmonic-wrapper{
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    z-index: 2;
  }
  .learn-body-wrapper, .mobile .learn-body-wrapper{
    border-radius: 0px !important;
    position:relative;
    box-shadow: none !important;
  }
  .learn-header-wrapper .ui-tab .number-wrapper{
    display:none;
  }
  .learn-header-wrapper .ui-tab:before{
    min-width: unset !important;
  }
  .learn-header-wrapper .ui-tab{
    min-width: unset !important;
  }
  .learn-fact-tooltip-wrapper{
    display:none;
  }

  .player-settings-tooltip-wrapper{
    font-size: 13px;
    font-weight: 100;
  }

  .audio-mode{
    width: 100%;
    height: 43px;
    margin-top: 8px;

    .btn{
      float: none !important;
      height: 19px;
      width: 100%;
      line-height: 0.7em;
      font-weight: 100;
      font-size: 11px;
    }
  }

  .prelearn-overlay:not(.guest){
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    border-radius: 0 !important;
    
    .prelearn-modal-content {
      height: auto;
    }
    .modal-body {
      bottom: 56px;
      top: 0;
      transform: none;
      overflow-y: auto;
      //padding-top: 16px;
      //margin-bottom: 56px;
    }

  }

  .learn-control{
    margin-left: 8px !important;
  }
}

@media(max-width: 767px) {
  .learn-header-wrapper .tools-wrapper{
    width: 50px;
  }

  .learn-wrapper{
    &.subtitles-open{
      .learn-image-wrapper{
        height: calc(100% - 75px) !important;
      }

      .learn-subtitles{
        height: 75px;

        &__slider{
          font-size: 18px;
          line-height: 24px;
          width: 100%;
          padding: 30px 16px 0px 16px;
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .quiz.quiz-mode .learn-image-wrapper {
    height: 100%;
    z-index: 2;
  }
  .right-sidebar-open .learn-picmonic-wrapper, .right-sidebar-open .learn-fact-wrapper{
    display: none;
  }
  .quiz.quiz-mode .learn-picmonic-wrapper{
    z-index:1;
    border-bottom: 3px solid #3F3F3F;
    box-shadow: 0 0 5px rgba(0,0,0,0.7);
  }
  .learn-header-wrapper .tab-wrapper {
    width: calc(100% - 50px);
  }
  .right-sidebar-open .tab-wrapper{
    display: none;
  }
  .learn-wrapper, .mobile .learn-wrapper{
    margin:0px;
    width: 100%;
    padding-bottom:0px;
  }
  .circle-step-small{
    width: 18px;
    height: 18px;
    margin-top: -8px;
    margin-left: -8px;
    line-height: 17px;
    font-size: 9px;
  }
  .circle-step-large-back{
    width: 30px;
    height: 30px;
    font-size: 17px;
    line-height: 28px;
  }
  .circle-step-wrapper{
    height: 45px !important;
    width: 30px;
    display: inline-block;
    float: left;
  }
  .learn-summary-wrapper{
    width: 100%;
  }
  .right-sidebar-open .learn-right-sidebar-wrapper{
    width: 100%;
  }
  .circle-step-small-back{
    width: 20px;
    height: 20px;
    top: -8px;
    left: -8px;
  }
  .learn-step-wrapper.quiz {
    display: none;
  }
  .quiz.quiz-mode .learn-footer-wrapper{
    display:none;
  }
}

.player-settings-wrapper{
  width:50px;
  text-align:center;
  border-left: 1px solid #333;
  position: relative;
}
.player-settings-wrapper .icon-cog{
  padding-top: 19px;
  cursor: pointer;
  color: #CCC;
  transition: color 0.6s;
}

.player-settings-wrapper.open .icon-cog{
  color: #00a0df;
}

.player-settings-wrapper .dropdown-toggle:hover .icon-cog {
  color: $white;
}

.player-settings-tooltip-wrapper{
  width: 350px;
  position: absolute;
  background: #3f3f3f;
  box-shadow: 0 0 4px rgba(0,0,0,0.4) !important;
  bottom: 100%;
  right: 0px !important;
  top: auto !important;
  max-width: 100vw;
  left: auto;
}

@media(max-width: 480px) {
  .player-settings-tooltip-wrapper {
    width: calc(100vw - 52px) !important;
    right: 52px !important;
    bottom: 0px;
  }
}

@media(max-width: 320px) {
  .player-settings-wrapper {
    display: none;
  }
}

@media(max-width: 767px) {
  .player-settings-tooltip-wrapper {
    width: 290px;
  }
  .learn-footer-wrapper {
    .add-left {
      margin-left: 4px !important;
    }
    .add-right {
      margin-right: 4px !important;
    }
  }
  @media(min-width: 480px) {
    .player-settings-wrapper, .player-settings-wrapper .dropdown-toggle {
      width: 42px !important;
    }
    .player-settings-tooltip-wrapper {
      right: 42px !important;
      bottom: 0px;
    }
  }
  .postlearn-overlay{
    .up-next {
      margin-top: 12px;
    }
    .pls-h2 {
      font-size: 32px;
      line-height: 36px;
      padding-top: 46px;
    }

  }
}

.player-settings-tooltip-wrapper, .player-settings-tooltip-wrapper ul{
  padding: 0px;
  margin: 0px;
}

.player-settings-tooltip-wrapper li{
  text-align: left;
  list-style: none;
  padding-left: 10px;
  line-height: 30px;
  border-bottom: 1px solid #363636;
}

.player-settings-tooltip-wrapper .toggle-wrapper{
  margin-top: 12px;
}

.player-settings-tooltip-wrapper .slider-wrapper{
  margin-top: 12px;
}

.player-settings-wrapper .dropdown-toggle{
  width: 50px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  display: block;
}

@media all and (max-width: 990px) {
  .related-picmonics-modal h1 {
    font-size: 16px;
  }
}

@media all and (min-width: 990px) {
  .sidescroll-wrapper .picmonic-tile.ditto {
    width : 350px;
    min-height : 380px;
  }

  .sidescroll-wrapper .picmonic-tile.ditto .card-options .card-options-submenu {
    max-height : 340px;
  }
}

.left-sidebar-wrapper, .workflow-sidebar-wrapper {
  .fact-list-header {
    padding: 16px;
    font-size: 16px;
    position: relative;
    font-family: Raleway;
    font-weight: Bold;
    color: $black;

    .create-button {
      @extend %editor_header_button;
      line-height: 28px;
      background: $blue;

      &:hover {
        background: $dark_blue;
      }
    }

    .delete-button {
      @extend %editor_header_button;

      background: $mugatu;

      &:hover {
        background: darken($mugatu, 10%);
      }
    }
  }

  .player-sidebar-wrapper__content{
    .fact-list-header {
      padding: 16px 0px;
    }
  }
}

%editor_header_button{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  float: right;
  line-height: 26px;
  text-align: center;
  transition: background 0.4s;
  color: $white;
  cursor: pointer;
  margin-top: -4px;
  font-size: 14px;
}


.player-sidebar-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index:1;
  background: $cloudier;
  border-top-left-radius: 5px;

  form{
    height: 100%;
  }

  .dropdown-menu{
    max-width: 296px;
  }

  .more-menu {
    display: none;
  }

  .selected .more-menu {
    display: block;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px 16px 16px 64px;
    width: 100%;
  }

  &__content {
    padding: 16px;
    height: calc(100% - 110px);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    input:not([type='checkbox']):not([type='radio']):not(.pls-input--orange){
      @extend %fact-edit-inputs;

      &.picmonic-title{
        color: $blue;
      }
    }


    select{
      @extend %fact-edit-inputs;
      background: #fff url("../img/arrowdown.gif") no-repeat 96% 50%;

      &:not(.has-value){
        color: #AAA !important;
      }

      &:after{
        content:"\f0d7";
        position: absolute;
        right: 5px;
        top: 3px;
      }
    }

    .search-bar-wrapper{
      background: $white;
      border-radius: 5px;
      border: 1px solid $wet-cement;

      &.has-focus{
        border-color:$blue;
      }

      input{
        width: calc(100% - 39px) !important;
        border: none !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-right: none !important;
        padding-left: 10px !important;
      }

      .search-icon-wrapper{
        height: 37px;
        width: 37px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: $magnum;
        vertical-align: top;
        margin-left: -3px;
        border: none;

        i{
          line-height: 38px;
        }
      }
    }

    .search-wrapper {
      .image-upload{
        border-radius: 4px;
        border: dashed 1px $blue;
        text-align: center;
        margin-top: 16px;
        font-size: 16px;
        font-family: $raleway;
        padding: 8px;
        cursor: pointer;
        margin-bottom:8px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $blue;
        min-height: 36px;
        max-height: 36px;

        i {
          width: 24px;
        }

        span {
          width: calc(100% - 48px);
          font-weight: bolder;
        }

        &:hover, &.dragover {
          color: $blue;
          border-color: $blue;
        }
      }
      .search-results {
        margin-top: 10px;
        margin-left: -16px;
        padding-left: 16px;

        .scroll-wrapper {
          padding-bottom: 10px;

          .result {
            background: #dbdbdb;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;

            .loading-overlay{
              position: absolute;
              z-index: 99;
              width: 100%;
              height: 100%;
              top:0;
              left:0;
              background: rgba(0,0,0,0.4);

              i{
                color: $white;
                position: absolute;
                top: calc(50% - 10px);
                left: calc(50% - 10px);
                font-size: 2em;
              }
            }

            p {
              padding: 8px;

              sub {
                position:static;
                vertical-align: sub;
              }
            }

            .asset-wrapper {
              background: $wet-cement;
              text-align: center;

              canvas {
                max-width: 106px;
                pointer-events: none;
              }
            }

            &.selected{
              background: $jullians-eyes;

              .asset-wrapper {
                background: $blue;
              }
            }

            &:hover{
              background: $white;
              cursor: pointer;
              box-shadow: 0 2px 5px rgba(0,0,0,0.25);

              .asset-wrapper {
                background: #dbdbdb;
              }
            }
          }
        }
      }
    }

    textarea{
      @extend %fact-edit-inputs;

      height: 75px !important;
      resize: none;
      line-height: 1.5em;
      padding-top: 5px;
    }

    &--no-footer{
      height: calc(100% - 51px);
    }

    &--larger-footer{
      height: calc(100% - 180px);
    }
  }

  &--scrollable{
    overflow-y: auto;
  }

  .left-sidebar-wrapper & {
    .editing-fact &:not(.fact-editor-wrapper){
      left: -100% !important;
      z-index:2;
    }
  }

  body.editor &{
    padding-left: 48px;

    .my-fact-wrapper, .fact-list-wrapper{
      margin-left: -15px;
    }
  }
}

%fact-edit-inputs{
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: $white;
  box-shadow: none;
  border: 1px solid $wet-cement;
  border-radius: 4px;
  width: 100%;
  color: $magnum;
  max-width: 100%;
  height: 38px;
  padding: 8px;

  &:focus{
    border-color: $blue;
  }
}

%fact-number{
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  border: 1px solid $orange;
  color: $light-grey-80;
  h4 {
    margin-top: 4px;
  }

  @extend %fact-number-colors;
}

.my-facts .learn-fact-wrapper i.icon-user { margin-top: 12px; }

%fact-number-colors{
  &.viewed, &.reviewed, &.attempted{
    border-color: $light-grey-80;
  }

  &.correct{
    border-color: $green;

  }

  &.incorrect{
    border-color: $mugatu;
  }
}

.toast-user-joined{
  left: 0px;
  top: 200px;
}

.comments-container {
  overflow: auto;
  height: calc(100vh - 475px);
  .comments-container {
    height: auto;
    overflow: visible;
  }
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  display: block;
  color: #aaa;
}

.workflow.comment-textarea[contenteditable=true] {
  min-height: 48px;
}
.workflow.comment-textarea{
  overflow-y:auto;
}
.workflow.comment-textarea, .comment-body-wrapper {
  span.tag {
    color: $blue;
  }
}
.tag-dropdown {
  width: 100%;
  .dropdown-menu {
    display: block;
    width: calc(100% - 30px);
    li.selected {
      background-color: $blue-steel;
      a { color: $white; }
    }
  }
}

.review-footer{
  width: calc(100% - 115px);
  float: left;
}

.flex-row {
  justify-content: space-between;
  break-inside: avoid;
  column-count: 4;
}

@media(max-width: 768px) {
  .flex-row {
    column-count: 3;
  }
}

@media(max-width: 480px) {
  .flex-row {
    column-count: 1;
  }
}

.keyboard-shortcut {
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 8px;
  display: inline-block;
  width: 100%;
  color: $magnum;

  &--key {
    width: auto;
    padding: 8px 16px;
    strong {
      font-size: 24px;
    }
  }

  strong {
    color: black;
    display: block;
    margin-bottom: 8px;
    font-size: 18px;
  }
}

@media(max-width: 640px) {
  .keyboard-shortcut {
    strong {
      font-size: 16px;
      margin-bottom: 4px;
    }
    &--key {
      padding: 4px 8px;
      strong {
        font-size: 18px;
      }
    }
  }
}

.keyboard-shortcut-modal {
  .modal-content {
    margin-left: 56px;
  }

  @media(max-width: 480px) {
    .modal .modal-content {
      margin-left: 0;
    }
  }
}


.fact-recorder{
  width: 288px;
  position: relative;
  height: 96px;

  &--analyser{
    position: absolute;
    z-index: 0;

    .audio-analyser{
      width: 100%;
      height: 100%;
    }
  }

  &--controls{
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    button{
      width: 41px;
      height: 41px;
      border-radius: 50%;
      background: $mugatu;

      i{
        color: $white;
        font-size: 30px;
        line-height: 37px;
      }
    }
  }
}

.fact-player{
  height: 32px;
  width: 100%;

  audio{
    width: 100%;
  }
}

body[modality="keyboard"] .learn-body .learn-body-block__header:focus {
  background: $blue;
  color: $white;
}

.learn-body{
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  background: $platinum;

  .learn-body-block{
    margin: 0 -15px 8px -15px;

    p{
      font-size: 14px;
      line-height: 24px;
    }

    &__header{
      cursor: pointer;
      color: $blue;
      background: $white;
      display: flex;
      align-items: center;
      height: 56px;

      h2{
        flex: 1 1 auto;
        line-height: 18px !important;
        margin: 0;
      }

      span{
        flex: 0 0 auto;
      }

      &:hover {
        background: $blue;
        color: $white;
      }
    }

    &__content{
      margin-top: 2px;
      margin-left: 8px;
      background: $white;
      line-height: 24px;
    }
  }

  .learn-body-fact{
    display: flex;

    &__number{
      flex-grow: inherit;
      padding-right: 8px;
      color: $wet-cement;
      width: 48px;
    }

    &__content{
      flex: 1;

    }

  }
}

.player-controls {
  background-color: $deep-sea;
  color: $white;
  width: 100%;
  padding: 8px;
  clear: both;
  height:68px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
   @media(max-width: 768px) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
   }
  transition: margin 1s;

  .volume-slider {
    width: 48px;
    height: 2px;
    border-radius: 0;
    background-color: $deep-sea;
    border: none;
    padding: 10px 0px;
    margin-top: -3px;

    .slider-filler {
      height: 2px;
      background-color: $wet-cement;
      width: 100%;
      float: right;
      display: block;
    }

    .slider-handle {
      height: 2px;
      border-radius: 0px;
      background-color: $white;
      border: none;
      position: relative;
      min-width: 22%;

      .scrub-handle {
        width: 12px;
        height: 12px;
        background-color: #fff;
        display: block;
        position: absolute;
        right: -6px;
        top: -5px;
        border-radius: 6px;
      }
    }
  }
}

.show-post-bar {
  .player-controls {
    margin-top: 52px;
  }
}

.audio-time-bar {
  height: 4px;
  border-radius: 2px;
  background-color: $blue-steel;
  margin-right:20px;
  margin-top:6px;
  position: relative;

  .bar {
    height: 4px;
    border-radius: 2px;
    background-color: $blue;
    position: relative;

    .scrub-handle {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $blue;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      position: absolute;
      right: -8px;
      top: -6px;
      cursor: pointer;
      z-index: 1;
    }
  }

  .fact-marker {
    width: 8px;
    height: 4px;
    border-radius: 2px;
    background-color: $jullians-eyes;
    position: absolute;
    top: 0;
  }
}
.audio-selector {
  padding: 4px 8px;
  border-radius: 4px;

  &.selected {
    background-color: $white;
    color: black;
  }

  &:hover {
    background-color: $blue;
    color: $white;
  }
}

.audio-pause {
  font-size: 42px;
  margin: 0 3px;
}

.audio-selector-indicator-icon {
  margin-top: 5px;
  margin-left: -8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}

.learn-info--public {
  max-width: 940px;
  margin: 0 auto;

  .learn-body {
    background-color: transparent;
    box-shadow: none;
  }
}
.picmonic-public-overlay-container {
  background-size: 100% 100%;
}
.audio-best-practices {
  margin-top: -9px;
}