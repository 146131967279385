.tweet-box {
  width: 100%;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 2px 0 0 $deep-sea;
  border: solid 1px $deep-sea;
  margin: 16px;
  margin-left: 0px;
  padding: 16px;

  &-title {
    font-size: 16px;
    line-height: 14px;
  }

  &-img{
    border-radius: 4px;
    margin-right: 16px;
    height: 66px;
    margin-bottom: 16px;
  }
}

.testimonial-img, .testimonial-text{
  width: 100%;
}

.stats-card {
  width: 164px;
  height: 137px;
  display: inline-block;
  border-radius: 4px;
  background: $white;
  box-shadow: 0 2px 0 0 $dry-cement;
  min-width: 140px;
  flex-grow: 0;
  padding: 8px;
  margin: 8px;
  text-align: center;

  a {
    text-transform: uppercase;
  }

  &-tall {
    height: 172px;
    width: 175px;
  }

  @media (max-width: 768px) {
    &-transparent {
      display: block;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &-new {
      width: 100%;
      text-align: left;
      height: auto;

      .padding-mobile {
        padding-top: 7px;
        position: relative;

        i {
          position: absolute;
          font-size: 36px;
          right: 0;
          top: 18px;
        }
      }

      a {
        display: block;
        text-transform: none;
      }
      img {
        float: left;
        padding-right: 15px;
      }
      strong {
        display: inline !important;
      }
    }
  }

  &-transparent {
    background: none;
    box-shadow: none;
  }

  img {
    clear: both;
  }
  strong {
    clear: both;
    display: block;
  }
}