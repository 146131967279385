.pricing-banner {

  &--fixed {
    position: fixed;
    width: 100%;
    z-index: 999;
  }

  &--hidden {
    visibility: hidden;
  }

  @media (max-width: 768px) {
    &--fixed {
      display: none;
    }

    &--hidden {
      visibility: visible;
    }
  }

  .countdown-timer {

    &-container {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    &-unit-container {
      margin-left: auto;
      margin-right: auto;
      width: 210px;
      float: left;
    }

    &-text {
      width: 184px;
      float: left;
    }

    &-column {
      width: 69px;
      line-height: 16px;
      float:left;
    }

    @media (max-width: 420px) {
      &-container {
        width: auto;
      }

      &-text {
        width: auto;
        float: none;
      }

      &-unit-container {
        margin-top: 8px;
        float: none;
      }
    }

    @media (min-width: 992px) and (max-width: 1230px) {
      &-container {
        width: auto;
      }

      &-text {
        width: auto;
        float: none;
      }

      &-unit-container {
        margin-top: 8px;
        float: none;
      }
    }
  }
}