body{
  margin: 0px;
  padding: 0px;
}

a{
  color: $dark-grey;
  cursor: pointer;
}

ul{
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.relative {
  position: relative;
}
.nowrap {
  white-space: nowrap;
}

.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
  &-fixed {
    position: fixed;
  }
}
