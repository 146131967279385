.icon-brain-plus:before {
    content: "\e600";
}

.icon-pause-circle-o:before {
    content: "\e601";
}

.icon-note-o:before {
    content: "\e602";
}

.icon-note-text-o:before {
    content: "\e603";
}

.icon-brain:before {
    content: "\e604";
}

.icon-map-o:before {
    content: "\e605";
}
.icon-map:before {
    content: "\e606";
}

.icon-pathways:before {
    content: "\e607";
}

.icon-playlist-plus:before {
    content: "\e608";
}

.icon-playlist:before {
    content: "\e609";
}

.icon-library-plus:before {
    content: "\e60a";
}

.icon-library:before {
    content: "\e60b";
}
.icon-playlist-check:before {
    content: "\e900";
}
.icon-playlist-add:before {
    content: "\e901";
}
.icon-edit-pencil:before{
    content: "\e902";
}